<template>
  <div
    class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900"
  >
    <div class="container mx-auto">
      <div
        class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center"
      >
        <h1
          class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500"
        >
          {{ $t("navbar.function") }}
        </h1>
      </div>

      <a
        onclick="window.history.back()"
        class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4 mr-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
          />
        </svg>
        <span>{{ $t("table.go-back") }}</span>
      </a>

      <nav
        aria-label="Breadcrumb"
        class="2xl:block xl:block lg:block md:block hidden"
      >
        <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
          <li
            class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900"
          >
            <a
              @click="this.$router.push('/')"
              class="inline-flex items-center font-medium"
            >
              <svg
                class="w-4 h-4 mr-2"
                fill="white"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                ></path>
              </svg>
              <span class="hidden sm:block">{{
                $t("ceo-message.main-screen")
              }}</span>
            </a>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-gray-400"
                fill="white"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="ml-1 font-medium md:ml-2 select-none">{{
                $t("navbar.function")
              }}</span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div
    class="container mx-auto border m-5 rounded-xl shadow py-5 bg-slate-50/25 dark:bg-gray-777 dark:border-lime-500 ml-5%"
  >
    <div class="dark:bg-gray-900 dark:text-lime-400 py-10">
      <div class="container mx-auto">
        <div class="dark:bg-gray-900">
          <div
            class="container mx-auto text-justify 2xl:px-0 xl:px-0 lg:px-0 md:px-2 sm:px-5 px-5 2xl:w-2/3 xl:w-2/3 lg:w-2/3 w-full"
          >
            <ol class="relative border-l border-gray-200 dark:border-lime-400">
              <li class="mb-10 ml-4">
                <div
                  class="absolute w-3 h-3 bg-blue-500 rounded-full mt-1.5 -left-1.5 border border-white dark:bg-lime-400">
                  <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-blue-400 opacity-75"> 
                  </span>
                </div>
                <time
                  class="mb-1 font-bold leading-none text-blue-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-header-1") }}
                </time>
                <p class="font-normal text-gray-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-1") }}
                </p>
                <p class="font-normal text-gray-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-2") }}
                </p>
                <p class="font-normal text-gray-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-3") }}
                </p>
                <p class="font-normal text-gray-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-4") }}
                </p>
              </li>
              <li class="mb-10 ml-4">
                <div
                  class="absolute w-3 h-3 bg-blue-500 rounded-full mt-1.5 -left-1.5 border border-white dark:bg-lime-400">
                  <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-blue-400 opacity-75"> 
                  </span>
                </div>
                <time
                  class="mb-1 font-bold leading-none text-blue-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-header-2") }}
                </time>
                <p class="font-normal text-gray-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-5") }}
                </p>
                <p class="font-normal text-gray-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-6") }}
                </p>
              </li>
              <li class="mb-10 ml-4">
                <div
                  class="absolute w-3 h-3 bg-blue-500 rounded-full mt-1.5 -left-1.5 border border-white dark:bg-lime-400">
                  <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-blue-400 opacity-75"> 
                  </span>
                </div>
                <time
                  class="mb-1 font-bold leading-none text-blue-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-header-3") }}
                </time>
                <p class="font-normal text-gray-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-7") }}
                </p>
                <p class="font-normal text-gray-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-8") }}
                </p>
                <p class="font-normal text-gray-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-9") }}
                </p>
                <p class="font-normal text-gray-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-10") }}
                </p>
                <p class="font-normal text-gray-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-11") }}
                </p>
                <p class="font-normal text-gray-500 dark:text-lime-400">
                  {{ $t("mission.mission-body-12") }}
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>