<template>
    <div @click="storeNews(id)" class="relative p-5 cursor-pointer bg-white hover:bg-blue-50 hover:text-blue-500 hover:shadow dark:bg-gray-777 dark:text-lime-500 dark:hover:bg-gray-900">
        <img :src="imageSrc" alt="Image" class="w-full h-56 object-cover mb-4">
        <h3 class="2xl:text-lg xl:text-lg lg:text-lg md:text-lg sm:text-lg font-bold leading-tight pb-5">{{ title }}</h3>
        <div class="flex justify-between items-center w-full pr-8 absolute bottom-0 mb-2">
            <p class="">{{ date }}</p>
            <button class="flex justify-center items-center space-x-2">
              <p>{{ $t('home-page.watch') }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
              </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      id: {
        type: Number,
        required: true
      },
        imageSrc: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        date: {
            type: String,
            required: true
        }
    },
  methods: {
    storeNews(id) {
      sessionStorage.setItem("NEWS_ID", id);
      this.$router.push("/newsDetail");
    },
  }
}
</script>