<template>
    <div v-if="loading">
      <LoadingState></LoadingState>
    </div>
    <div>
      <div
        class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900"
      >
        <div class="container mx-auto">
          <div
            class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center"
          >
            <h1
              class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500"
            >
              {{ $t("navbar.vacancy-result") }}
            </h1>
          </div>
  
          <a
            onclick="window.history.back()"
            class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            <span>{{ $t("table.go-back") }}</span>
          </a>
  
          <nav
            aria-label="Breadcrumb"
            class="2xl:block xl:block lg:block md:block hidden"
          >
            <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
              <li
                class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900"
              >
                <a
                  @click="this.$router.push('/')"
                  class="inline-flex items-center font-medium"
                >
                  <svg
                    class="w-4 h-4 mr-2"
                    fill="white"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                    ></path>
                  </svg>
                  <span class="hidden sm:block">{{
                    $t("ceo-message.main-screen")
                  }}</span>
                </a>
              </li>
              <li>
              <div class="flex items-center">
                <svg class="w-6 h-6 text-gray-400" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"></path>
                </svg>
                <div class="ml-1 font-medium select-none">{{ $t('navbar.vacancy') }}</div>
              </div>
            </li>
              <li aria-current="page">
                <div class="flex items-center">
                  <svg
                    class="w-6 h-6 text-gray-400"
                    fill="white"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span class="ml-1 font-medium md:ml-2 select-none">{{
                    $t("home-page.vacancy-result")
                  }}</span>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div
        class="container mx-auto border dark:border-lime-600 m-5 rounded-xl shadow pb-5 bg-slate-50/25 dark:bg-gray-900"
      >
        <div class="container mx-auto my-auto">
            <div class="mt-5 basis-2/5 my-auto text-center">
              <h1
                class="text-xl font-extrabold text-gray-900 dark:text-white 2xl:whitespace-nowrap xl:whitespace-nowrap lg:whitespace-nowrap md:whitespace-nowrap uppercase"
              >
              <p>
                  <span class="text-blue-500 dark:text-white">{{
                    $t("vacancyResult.header")
                  }}</span>
                </p>
              </h1>
              <hr
                class="w-full md:w-64 md:mx-auto h-px bg-gray-200 border-0 dark:bg-lime-400"
              />
            </div>
            <div class="w-full mb-3">
              <table class="table-auto 2xl:w-8/12 2xl:mt-10 2xl:mb-5 text-center 2xl:ml-60 p-11 justify-center items-center">
                    <thead class=" from-blue-600 via-sky-600">
                      <tr class="border-blue-500 border-2  dark:text-lime-500 dark:border-lime-500">
                       <th class="bg-blue-500 text-white p-4 dark:bg-gray-900  dark:text-lime-500" >{{ $t("vacancyResult.fullName") }}</th>
                       <th class="bg-blue-500 text-white p-4 dark:bg-gray-900  dark:text-lime-500" >{{ $t("vacancyResult.result") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(vacancy, index) in vacancies" :key="index" >
                        <td v-if="vacancy.result == 'success'" class="w-1/2 text-black border-lime-300 bg-lime-300 border-2 m-5 p-2 dark:text-black dark:border-lime-500">{{ vacancy.fullName }}</td>
                        <td v-else class="w-1/2 text-black border-red-500 bg-red-500 border-2 m-5 p-2 dark:text-black dark:border-lime-500">{{ vacancy.fullName }}</td>
                        <td v-if="vacancy.result == 'success'" class="w-1/2 text-black border-lime-300 bg-lime-300 border-2 m-5 p-2 dark:text-black dark:border-lime-500">{{ $t(vacancy.result) }}</td>
                        <td v-else class="w-1/2 text-black border-red-500 bg-red-500 border-2 m-5 p-2 dark:text-black dark:border-lime-500">{{ $t(vacancy.result) }}</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import LoadingState from "@/components/LoadingState";
  import axios from 'axios'
  export default {
    components: {
      LoadingState,
    },
    data() {
      return {
        loading: true,
        applications: null,
        vacancyId: 0
      };
    },
    methods: {
        loadTable() {
        axios
            .get(`/api/Website/ApplicationForVacancy/GetApplications/` + this.vacancyId)
            .then((response) => {
                this.vacancies = response.data;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
              this.loading = false;
            });
      },
    },
    mounted() {
         
        if (sessionStorage.getItem("VACANCY_ID")) {
              this.vacancyId = JSON.parse(sessionStorage.getItem("VACANCY_ID"));
              this.loadTable();
            }
        },
  };
  </script>
  