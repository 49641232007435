<template>
  <div class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900">
    <div class="container mx-auto">
      <div class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center">
        <h1 class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500">{{ $t('table.searchForm') }}</h1>
      </div>

      <a onclick="window.history.back()" class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
        </svg>
        <span>{{ $t('table.go-back') }}</span>
      </a>

      <nav aria-label="Breadcrumb" class="2xl:block xl:block lg:block md:block hidden">
        <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
          <li class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900">
            <a @click="this.$router.push('/')" class="inline-flex items-center font-medium">
              <svg class="w-4 h-4 mr-2" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              <span class="hidden sm:block">{{ $t('ceo-message.main-screen') }}</span>
            </a>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg class="w-6 h-6 text-gray-400" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"></path>
              </svg>
              <span class="ml-1 font-medium md:ml-2 select-none">{{ $t('table.searchForm') }}</span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="container mx-auto border dark:border-lime-600 m-5 rounded-xl shadow py-5 bg-slate-50/25 dark:bg-gray-900">
      <div class="flex border-b 2xl:px-20 pb-5 space-x-5 dark:border-lime-500">
        <div class="relative basis-4/5">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor"
                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
          <input type="text" v-model="query" @keyup.enter="search(1)"
                 class="block w-full p-[10px] pl-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-777 dark:text-lime-400 dark:focus:ring-lime-400 dark:border-lime-400">
        </div>
        <button
            @click="search(1)"
            class="basis-1/5 rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 dark:from-lime-500 dark:via-lime-600 dark:to-lime-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center"
        >
          {{ $t('table.search') }}
        </button>
      </div>
    <div v-show="this.hasData" class="container mx-auto">
      <div>
        <div v-for="item in paginatedData" :key="item.title" class="2xl:px-20 py-5 border-b dark:border-lime-500">
          <a v-bind:href="item.link" target="_blank"> <b class="text-blue-500 dark:text-lime-500">{{item.title}}</b> </a> <br>
          <span class="text-sm dark:text-white">{{item.snippet}}</span> <br>
          <a v-bind:href="item.link" target="_blank" class="underline hover:text-blue-500 dark:hover:text-lime-700 text-sm dark:text-white"> {{item.link}}</a> <br>
        </div>
        <div class="flex flex-col items-center mt-5">
          <div class="flex text-gray-700 space-x-1">
            <button @click="previousPage" :disabled="currentPage === 1"
                    class="h-7 w-7 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 dark:hover:border-lime-500 dark:hover:text-lime-500 transition-all ease-linear cursor-pointer rounded-md">
              <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" fill="none"
                   viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"
                   stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-chevron-left w-6 h-6">
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </button>
            <span v-for="pageNumber in pageNumbers" :key="pageNumber"
                  class="flex h-7 font-medium bg-white border transition-all ease-linear rounded-md">
                                                    <button @click="goToPage(pageNumber)"
                                                            :disabled="currentPage === pageNumber"
                                                            :class="{ active: currentPage === pageNumber }"
                                                            class="w-7 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-md">
                                                        {{ pageNumber }}
                                                    </button>
                                                </span>

            <button @click="nextPage" :disabled="currentPage === totalPages"
                    class="h-7 w-7 ml-1 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 dark:hover:border-lime-500 dark:hover:text-lime-500 transition-all ease-linear cursor-pointer rounded-md">
              <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" fill="none"
                   viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"
                   stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-chevron-right w-6 h-6">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="this.hasData == false" class="flex flex-col justify-center items-center mt-5 dark:text-lime-500">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
      </svg>
      <h2 class="text-center">{{ $t('table.doesnt-exist') }}</h2>
    </div>
    </div>

</template>

<script>
import axios from 'axios'
export default {
  // eslint-disable-next-line
  name: 'search',
  data(){
    return{
      items: [],
      query: '',
      activeIndex: null,
      currentPage: 1,
      itemsPerPage: 5,
      hasData: false
    }
  },
  methods : {
    async search(){
      await axios.get('https://customsearch.googleapis.com/customsearch/v1?cx=fe76ec7fbc35e88aa&q='+this.query+'&key=AIzaSyA712WevTNq210G22y9uIJDSn-TFGreZ8k')
        .then(response =>{
           if(response.data.items){
             this.hasData = true;
             this.items = response.data.items;
           }
           else {
             this.items = [];
             this.hasData = false;
           }
        })
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
  },
  computed: {
    filteredDataYear() {
      return this.items;
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredDataYear.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredDataYear.length / this.itemsPerPage);
    },
    pageNumbers() {
      const pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    },
  }
}
</script>
