<template>
  <div class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900">
    <div class="container mx-auto">
      <a onclick="window.history.back()" class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
        </svg>
        <span>{{ $t('table.go-back') }}</span>
      </a>

    </div>
  </div>

  <div class="container mx-auto border rounded-lg my-10 shadow dark:border-lime-500 dark:bg-gray-900 dark:text-lime-500">
    <img src="@/assets/img/dev.svg" alt="" class="mx-auto py-10">

    <h2 class="text-center 2xl:text-2xl lg:text-2xl md:text-xl text-base mb-10">{{ $t('others.developing') }}</h2>
  </div>

</template>
<script setup>
</script>