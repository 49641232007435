<template>
  <div class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900">
    <div class="container mx-auto">
      <div class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center">
        <h1 class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500">{{ $t('navbar.our-reviews') }}</h1>
      </div>

      <a onclick="window.history.back()" class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
        </svg>
        <span>{{ $t('table.go-back') }}</span>
      </a>

      <nav aria-label="Breadcrumb" class="2xl:block xl:block lg:block md:block hidden">
        <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
          <li class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900">
            <a @click="this.$router.push('/')" class="inline-flex items-center font-medium">
              <svg class="w-4 h-4 mr-2" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              <span class="hidden sm:block">{{ $t('ceo-message.main-screen') }}</span>
            </a>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg class="w-6 h-6 text-gray-400" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"></path>
              </svg>
              <span class="ml-1 font-medium md:ml-2 select-none">{{ $t('navbar.our-reviews') }}</span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>

<div class="container mx-auto border dark:border-lime-600 m-5 rounded-xl shadow bg-slate-50/25 dark:bg-gray-777 dark:text-lime-500 p-2 space-y-5">
    <div
        class="text-justify xl:px-5 xl:py-3 indent-16
            md:px-5 md:py-3 sm:px-5 sm:py-3 px-5 py-3 border rounded-xl border-blue-200 shadow 2xl:px-10 2xl:py-10 dark:bg-gray-900 dark:shadow-none dark:border-none">
      {{ $t('review.review-body-1') }}
      <div class="flex justify-end">
        <img
            src="~@/assets/hamkorBank.png"
            class="2xl:mt-4 2xl:ml-32 2xl:-mb-2 mt-1 2xl:w-96 2xl:-mr-12"
            alt=""></div>
    </div>

    <div
        class="text-justify xl:px-5 xl:py-3 indent-16
            md:px-5 md:py-3 sm:px-5 sm:py-3 px-5 py-3 border rounded-xl border-blue-200 shadow 2xl:px-10 2xl:py-10 dark:bg-gray-900 dark:shadow-none dark:border-none">
      {{ $t('review.review-body-2') }}
      <div class="flex justify-end">
        <img
            src="~@/assets/uzbekenergo.svg"
            class="2xl:mr-2 2xl:w-60 2xl:mt-4 2xl:-mb-2 xl:mr-10 lg:mr-14 md:mr-10 sm:mr-12 mr-12 mt-1 dark:opacity-50"
            alt=""></div>
    </div>

    <div
        class="indent-16 text-justify xl:px-5 xl:py-3
            md:px-5 md:py-3 sm:px-5 sm:py-3 px-5 py-3 border rounded-xl border-blue-200 shadow 2xl:px-10 2xl:py-10 dark:bg-gray-900 dark:shadow-none dark:border-none">
      {{ $t('review.review-body-3') }}
      <div class="flex justify-end">
        <img
            src="~@/assets/ozbekistonTemirYollari.png"
            class="2xl:mr-10 2xl:w-48 2xl:mt-4 2xl:-mb-2 xl:mr-20 lg:mr-24 md:mr-20 sm:mr-24 mr-24 mt-1"
            alt=""></div>
    </div>
</div>
</template>