<template>
  <div class="rounded-lg flex dark:text-lime-500">
    <div class="flex">
      <img :src="image" alt="image" class="w-[90px] h-[90px]">
      <div class="flex flex-col justify-start ml-3">
        <h2 class="2xl:text-xl xl:text-xl lg:text-base md:text-base text-sm pt-2">{{ header }}</h2>
        <h3 class="text-blue-500 dark:text-white font-bold 2xl:text-lg xl:text-lg lg:text-lg md:text-lg">{{ description }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
};
</script>