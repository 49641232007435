<template>
  <div
    class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900"
  >
    <div class="container mx-auto">
      <div
        class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center"
      >
        <h1
          class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500"
        >
          {{ $t("navbar.advisory-services") }}
        </h1>
      </div>

      <a
        onclick="window.history.back()"
        class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4 mr-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
          />
        </svg>
        <span>{{ $t("table.go-back") }}</span>
      </a>

      <nav
        aria-label="Breadcrumb"
        class="2xl:block xl:block lg:block md:block hidden"
      >
        <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
          <li
            class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900"
          >
            <a
              @click="this.$router.push('/')"
              class="inline-flex items-center font-medium"
            >
              <svg
                class="w-4 h-4 mr-2"
                fill="white"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                ></path>
              </svg>
              <span class="hidden sm:block">{{
                $t("ceo-message.main-screen")
              }}</span>
            </a>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-gray-400"
                fill="white"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="ml-1 font-medium md:ml-2 select-none">{{
                $t("navbar.advisory-services")
              }}</span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div
    class="container mx-auto border dark:border-lime-600 m-5 rounded-xl shadow dark:pb-5 bg-slate-50/25 dark:bg-gray-777"
  >
    <div class="container mx-auto">
      <div class="flex justify-center items-center space-x-20">
        <div
          class="dark:text-lime-400 2xl:px-20 xl:px-10 lg:px-10 md:px-10 sm:px-5 px-5 text-justify"
        >
          <blockquote
            class="p-4 my-4 border-l-4 border-blue-500 dark:border-lime-500"
          >
            <h2>{{ $t("itservice.itservice-body-center") }}</h2>
            <br />
            <h2>{{ $t("itservice.itservice-body-end") }}</h2>
          </blockquote>
        </div>
      </div>
    </div>
    <div class="dark:bg-gray-900 pt-6">
      <div class="container mx-auto dark:text-lime-400">
        <p
          class="2xl:text-xl xl:text-xl lg:text-xl md:text-xl sm:text-sm text-sm text-center font-bold dark:text-white"
        >
          {{ $t("consultservice.consultservice-body-header-1") }}
        </p>
        <ul
          class="space-y-4 list-disc list-inside text-black 2xl:px-20 2xl:mt-6 dark:text-lime-400"
        >
          <ol class="pl-5 mt-2 space-y-1 list-decimal list-inside">
            <li class="px-5">
              {{ $t("consultservice.consultservice-body-text-1") }}
            </li>
            <li class="px-5">
              {{ $t("consultservice.consultservice-body-text-2") }}
            </li>
            <li class="px-5 2xl:mr-10">
              {{ $t("consultservice.consultservice-body-text-3") }}
            </li>
            <li class="px-5 xl:mr-10">
              {{ $t("consultservice.consultservice-body-text-4") }}
            </li>
            <li class="px-5">
              {{ $t("consultservice.consultservice-body-text-5") }}
            </li>
          </ol>
        </ul>
        <p
          class="2xl:text-xl xl:text-xl lg:text-xl md:text-xl sm:text-sm text-sm text-center mt-6 font-bold dark:text-white"
        >
          {{ $t("consultservice.consultservice-body-header-2") }}
        </p>
        <ol
          class="space-y-4 list-decimal list-inside text-black mt-6 dark:text-lime-400"
        >
          <li class="2xl:px-32 px-5 text-justify">
            {{ $t("consultservice.consultservice-body-text-6") }}
            <ul
              class="pl-5 space-y-1 list-disc list-inside 2xl:mt-2 xl:mt-2 lg:mt-2 md:mt-2 sm:mt-2 mt-4 text-gray-500 dark:text-lime-600"
            >
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-7") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-8") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-9") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-10") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-11") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-12") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-13") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-14") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-33") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-34") }}
              </li>
            </ul>
          </li>
          <li class="2xl:px-32 px-5 text-justify">
            {{ $t("consultservice.consultservice-body-text-15") }} <br />
            {{ $t("consultservice.consultservice-body-text-16") }}
            <ul
              class="pl-5 space-y-1 list-disc list-inside 2xl:mt-2 xl:mt-2 md:mt-2 lg:mt-2 sm:mt-2 mt-4 text-gray-500 dark:text-lime-600"
            >
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-18") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-19") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-20") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-21") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-22") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-23") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-24") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-25") }}
              </li>
            </ul>
          </li>
          <li class="2xl:px-32 px-5 text-justify">
            {{ $t("consultservice.consultservice-body-text-26") }} <br />
            {{ $t("consultservice.consultservice-body-text-27") }}
            <ul
              class="pl-5 space-y-1 list-disc list-inside pb-4 2xl:mt-2 xl:mt-2 lg:mt-2 md:mt-2 sm:mt-2 mt-4 text-gray-500 dark:text-lime-600"
            >
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-28") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-29") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-30") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-31") }}
              </li>
              <li class="text-justify">
                {{ $t("consultservice.consultservice-body-text-32") }}
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>
