<template>
  <div
      class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900">
    <div class="container mx-auto">
      <div
          class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center">
        <h1 class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500">
          {{ $t('home-page.list-of-joint-stock') }}</h1>
      </div>

      <a onclick="window.history.back()"
         class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-4 h-4 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"/>
        </svg>
        <span>{{ $t('table.go-back') }}</span>
      </a>

      <nav aria-label="Breadcrumb" class="2xl:block xl:block lg:block md:block hidden">
        <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
          <li class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900">
            <a @click="this.$router.push('/')" class="inline-flex items-center font-medium">
              <svg class="w-4 h-4 mr-2" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              <span class="hidden sm:block">{{ $t('ceo-message.main-screen') }}</span>
            </a>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg class="w-6 h-6 text-gray-400" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"></path>
              </svg>
              <span class="ml-1 font-medium md:ml-2 select-none">{{ $t('home-page.list-of-joint-stock') }}</span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="container h-table mx-auto mt-navbar py-10 mb-10">
    <div class="flex justify-between">
      <h2 class="mb-4 text-xl font-extrabold leading-none tracking-tight text-blue-500 dark:text-lime-400 text-center">
        {{ $t('home-page.list-of-joint-stock') }}</h2>
      <button v-on:click="onBtnExport()"
              class="bg-blue-100 hover:bg-blue-300 text-blue-800 dark:text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-lime-400 border border-blue-400 dark:border-lime-900 mb-2 cursor-progress">
        {{ $t('others.csv') }}
      </button>
    </div>
    <div class="flex h-full">
      <AgGridVue
          class="2xl:basis-4/5 xl:basis-4/5 lg:basis-4/5 md:basis-4/5"
          style="width: 100%; height: 100%;"
          :class="themes"
          :columnDefs="datas"
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          :localeText="localeText"
          :enableCellTextSelection="true"
          :paginationAutoPageSize="true"
          :pagination="true"
          :animateRows="true"
          :rowData="rowData"
          :isExternalFilterPresent="isExternalFilterPresent"
          :doesExternalFilterPass="doesExternalFilterPass"
      ></AgGridVue>
      <div class="basis-1/5 bg-gray-50 dark:bg-gray-900 2xl:block xl:block lg:block md:block hidden">
        <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="everyone" v-on:change="externalFilterChanged('everyone')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="everyone" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.all-regions') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="andijan" v-on:change="externalFilterChanged('andijan')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="andijan" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.andijan') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="bukhara" v-on:change="externalFilterChanged('bukhara')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="bukhara" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.bukhara') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="jizzakh" v-on:change="externalFilterChanged('jizzakh')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="jizzakh" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.jizzakh') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="kashkadarya" v-on:change="externalFilterChanged('kashkadarya')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="kashkadarya" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.kashkadarya') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="navoi" v-on:change="externalFilterChanged('navoi')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="navoi" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.navai') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="namangan" v-on:change="externalFilterChanged('namangan')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="namangan" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.namangan') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="samarkand" v-on:change="externalFilterChanged('samarkand')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="samarkand" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.samarkand') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="surkhandarya" v-on:change="externalFilterChanged('surkhandarya')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="surkhandarya" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.surkhandarya') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="syrdarya" v-on:change="externalFilterChanged('syrdarya')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="syrdarya" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.syrdarya') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="tashkentc" v-on:change="externalFilterChanged('tashkentc')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="tashkentc" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.tashkent') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="tashkentr" v-on:change="externalFilterChanged('tashkentr')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="tashkentr" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.tashkent-region') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="fergana" v-on:change="externalFilterChanged('fergana')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="fergana" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.fergana') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="khorezm" v-on:change="externalFilterChanged('khorezm')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="khorezm" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.khorezm') }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                <input type="radio" name="filter" id="karakalpakstan"
                       v-on:change="externalFilterChanged('karakalpakstan')"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                <label for="karakalpakstan" class="font-medium text-gray-900 dark:text-gray-300"/>
                <p>{{ $t('others.karakalpakstan') }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {AgGridVue} from "ag-grid-vue3";

export default {
  components: {AgGridVue},
  data() {
    return {
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        suppressMenu: false,
        flex: 1,
      },
      autoGroupColumnDef: null,
      rowData: null,
      localeText: null,
      currentTheme: null,
    };
  },
  computed: {
    themes() {
      if (localStorage.getItem('theme') != 'dark') {
        return 'ag-theme-alpine';
      } else {
        return 'ag-theme-alpine-dark';
      }
    },
    datas() {
      return [
        {
          headerName: this.$t('others.inn'),
          field: 'inn',
          filterParams: {buttons: ['clear', 'reset', 'apply'],}
        },
        {
          headerName: this.$t('others.name'),
          field: 'name',
          filterParams: {buttons: ['clear', 'reset', 'apply'],}
        },
        {
          headerName: this.$t('others.address'),
          field: 'address',
          filterParams: {buttons: ['clear', 'reset', 'apply'],}
        },
        {
          headerName: this.$t('others.phone-number'),
          field: 'phoneNumber',
          filterParams: {buttons: ['clear', 'reset', 'apply'],}
        },
        {
          headerName: this.$t('others.denomination'),
          field: 'denomination',
          filterParams: {buttons: ['clear', 'reset', 'apply'],}
        },
        {
          headerName: this.$t('others.all-quantity'),
          field: 'allQuantity',
          filterParams: {buttons: ['clear', 'reset', 'apply'],}
        },
        {
          headerName: this.$t('others.total'),
          field: 'totalVolume',
          filterParams: {buttons: ['clear', 'reset', 'apply'],},
        },
        {
          headerName: this.$t('others.region'),
          field: 'regionCode',
          filterParams: {buttons: ['clear', 'reset', 'apply'],},
          hide: true
        },
      ]
    }
  },
  methods: {
    externalFilterChanged(newValue) {
      Regiontype = newValue;
      this.gridApi.onFilterChanged();
    },
    isExternalFilterPresent() {
      return Regiontype !== 'everyone';
    },
    doesExternalFilterPass(node) {
      if (node.data) {
        switch (Regiontype) {
          case 'andijan':
            return parseInt(node.data.regionCode) == 3;
          case 'bukhara':
            return parseInt(node.data.regionCode) == 6;
          case 'jizzakh':
            return parseInt(node.data.regionCode) == 8;
          case 'kashkadarya':
            return parseInt(node.data.regionCode) == 10;
          case 'navoi':
            return parseInt(node.data.regionCode) == 12;
          case 'namangan':
            return parseInt(node.data.regionCode) == 14;
          case 'samarkand':
            return parseInt(node.data.regionCode) == 18;
          case 'surkhandarya':
            return parseInt(node.data.regionCode) == 22;
          case 'syrdarya':
            return parseInt(node.data.regionCode) == 24;
          case 'tashkentc':
            return parseInt(node.data.regionCode) == 26;
          case 'tashkentr':
            return parseInt(node.data.regionCode) == 27;
          case 'fergana':
            return parseInt(node.data.regionCode) == 30;
          case 'khorezm':
            return parseInt(node.data.regionCode) == 33;
          case 'karakalpakstan':
            return parseInt(node.data.regionCode) == 35;

          default:
            return true;
        }
      }
      return true;
    },

    onBtnExport() {
      this.gridApi.exportDataAsCsv();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const updateData = (data) => {
        document.querySelector('#everyone').checked = true;
        params.api.setRowData(data);
      }
      fetch('https://web.uzcsd.uz/api/Security/GetAllIssuersInformation')
          .then((resp) => resp.json())
          .then((data) => updateData(data));
    },
  }
}
var Regiontype = 'everyone';
</script>

<style>
.rag-red {
  color: coral;
}

.rag-green {
  color: limegreen;
}

.ag-theme-alpine .ag-header-cell {
  color: #3F83F8;
  font-size: 14px;
}
</style>