<template>
  <div class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900">
    <div class="container mx-auto">
      <div class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center">
        <h1 class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500">{{ $t('footer.footer-map') }}</h1>
      </div>

      <a onclick="window.history.back()" class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
        </svg>
        <span>{{ $t('table.go-back') }}</span>
      </a>

      <nav aria-label="Breadcrumb" class="2xl:block xl:block lg:block md:block hidden">
        <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
          <li class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900">
            <a @click="this.$router.push('/')" class="inline-flex items-center font-medium">
              <svg class="w-4 h-4 mr-2" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              <span class="hidden sm:block">{{ $t('ceo-message.main-screen') }}</span>
            </a>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg class="w-6 h-6 text-gray-400" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"></path>
              </svg>
              <span class="ml-1 font-medium md:ml-2 select-none">{{ $t('footer.footer-map') }}</span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="container mx-auto border dark:border-lime-600 m-5 rounded-xl shadow bg-slate-50/25 dark:bg-gray-777 dark:py-5">
    <div class="2xl:flex xl:flex lg:flex md:flex 2xl:ml-0 xl:ml-0 lg:ml-0 md:ml-0 ml-5 justify-evenly items-center border-b dark:border-lime-500 py-3 dark:bg-gray-900">
      <div class="flex flex-col basis-1/5 text-start">
        <h2 class="dark:text-white">{{ $t('table.section') }}</h2>
        <p class="font-bold text-blue-500 text-2xl dark:text-lime-500">{{ $t('navbar.about-us') }}</p>
      </div>
      <ol class="basis-3/5 relative border-l border-gray-200 dark:border-lime-400">
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/profile')">{{ $t('profile.profile') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/ceoMessage')">{{ $t('navbar.ceo-message') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/history')">{{ $t('navbar.history') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/mission')">{{ $t('navbar.mission') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/function')">{{ $t('navbar.function') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/headliners')">{{ $t('navbar.headliners') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/structure')">{{ $t('navbar.structure') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/membership')">{{ $t('navbar.membership') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/vacancy')">{{ $t('navbar.vacancy') }}</button>
          </time>
        </li>
      </ol>
    </div>

    <div class="2xl:flex xl:flex lg:flex md:flex 2xl:ml-0 xl:ml-0 lg:ml-0 md:ml-0 ml-5 justify-evenly items-center border-b dark:border-lime-500 py-3 dark:bg-gray-900">
      <div class="flex flex-col basis-1/5 text-start">
        <h2 class="dark:text-white">{{ $t('table.section') }}</h2>
        <p class="font-bold text-blue-500 text-2xl dark:text-lime-500 ">{{ $t('navbar.our-services') }}</p>
      </div>
      <ol class="basis-3/5 relative border-l border-gray-200 dark:border-lime-400">
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/depoService')">{{ $t('navbar.depository-services') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/itService')">{{ $t('navbar.information-services') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/consultService')">{{ $t('navbar.advisory-services') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/developing')">{{ $t('navbar.evote-service') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/developing')">{{ $t('navbar.actual-tariffs') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/developing')">{{ $t('navbar.tariffs-archive') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/review')">{{ $t('navbar.our-reviews') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/contacts')">{{ $t('home-page.contact-us') }}</button>
          </time>
        </li>
      </ol>
    </div>

    <div class="2xl:flex xl:flex lg:flex md:flex 2xl:ml-0 xl:ml-0 lg:ml-0 md:ml-0 ml-5 justify-evenly items-center py-3 dark:bg-gray-900 border-b">
      <div class="flex flex-col basis-1/5 text-start">
        <h2 class="dark:text-white">{{ $t('table.section') }}</h2>
        <p class="font-bold text-blue-500 text-2xl dark:text-lime-500">{{ $t('navbar.interactive') }}</p>
      </div>
      <ol class="basis-3/5 relative border-l border-gray-200 dark:border-lime-400">
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/isinAndCfi')">{{ $t('home-page.list-of-assigned') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/issuersList')">{{ $t('home-page.list-of-joint-stock') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button disabled>{{ $t('home-page.central-bank') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button disabled>{{ $t('home-page.search-for-issuers') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/templates')">{{ $t('navbar.template-blanks') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/glossary')">{{ $t('navbar.dictionary-terms') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button disabled>{{ $t('navbar.questionnaire') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button disabled>{{ $t('navbar.online-appeal') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button disabled>{{ $t('navbar.frequenly-questions') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button onclick="location.href='https://pc.uzcsd.uz'">{{ $t('navbar.my-cabinet') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button onclick="location.href='https://evote.uz'">{{ $t('navbar.e-vote') }}</button>
          </time>
        </li>
      </ol>
    </div>

    <div class="2xl:flex xl:flex lg:flex md:flex 2xl:ml-0 xl:ml-0 lg:ml-0 md:ml-0 ml-5 justify-evenly items-center border-b dark:border-lime-500 py-3 dark:bg-gray-900">
      <div class="flex flex-col basis-1/5 text-start">
        <h2 class="dark:text-white">{{ $t('table.section') }}</h2>
        <p class="font-bold text-blue-500 text-2xl dark:text-lime-500 ">{{ $t('navbar.cooperate') }}</p>
      </div>
      <ol class="basis-3/5 relative border-l border-gray-200 dark:border-lime-400">
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/list-affiliated')">{{ $t('navbar.list-affiliated') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/essentialFacts')">{{ $t('navbar.essential-facts') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/businessPlan')">{{ $t('navbar.bussines-plan') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/businessExecution')">{{ $t('navbar.estimate-execution') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/kpi')">{{ $t('navbar.performance-indicators') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button disabled>{{ $t('navbar.development-strategy') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/financialReport')">{{ $t('navbar.annual-reports') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/msfo')">{{ $t('navbar.ifrs-reporting') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/purchases')">{{ $t('navbar.information-purchases') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/supervisory-board')">{{ $t('navbar.supervisory-board') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/executiveBody')">{{ $t('navbar.executive-body') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/developing')">{{ $t('navbar.internal-audit') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/internalDocuments')">{{ $t('navbar.internal-documents') }}</button>
          </time>
        </li>
      </ol>
    </div>

    <div class="2xl:flex xl:flex lg:flex md:flex 2xl:ml-0 xl:ml-0 lg:ml-0 md:ml-0 ml-5 justify-evenly items-center dark:border-lime-500 py-3 dark:bg-gray-900">
      <div class="flex flex-col basis-1/5 text-start">
        <h2 class="dark:text-white">{{ $t('table.section') }}</h2>
        <p class="font-bold text-blue-500 text-2xl dark:text-lime-500 ">{{ $t('navbar.press-center') }}</p>
      </div>
      <ol class="basis-3/5 relative border-l border-gray-200 dark:border-lime-400">
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/news')">{{ $t('navbar.news') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/pressRelease')">{{ $t('navbar.press-releases') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/publications')">{{ $t('navbar.publications') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/media')">{{ $t('navbar.photo') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button disabled>{{ $t('navbar.video') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/usefulLinks')">{{ $t('footer.useful-links') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/statisticalInformation')">{{ $t('navbar.statistical-information') }}</button>
          </time>
        </li>
        <li class="mb-3 ml-4">
          <div
              class="absolute w-3 h-3 bg-blue-500 mt-1.5 -left-1.5 border border-white dark:border-lime-400 dark:bg-lime-400">
          </div>
          <time class="mb-1 leading-none text-blue-500 dark:text-lime-400">
            <button @click="this.$router.push('/results')">{{ $t('navbar.results') }}</button>
          </time>
        </li>
      </ol>
    </div>

  </div>
</template>
<script setup>
</script>