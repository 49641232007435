<template>
    <div class="youtube-video">
      <iframe        
        :src="videoUrl"
        class="w-5/6 xl:h-pdfView md:h-80"

        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </template>
  
  <script>
  export default {
    name: 'YouTubeVideo',
    props: {
      videoId: {
        type: String,
        required: true
      }
    },
    computed: {
      videoUrl() {
        return `https://www.youtube.com/embed/${this.videoId}`;
      }
    }
  };
  </script>
  
  <style scoped>
  .youtube-video {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>