<template>
  <footer
    class="bg-gray-600 p-6 dark:bg-gray-900 text-white 2xl:text-base xl:base lg:base md:text-sm sm:text-sm text-xs transition-all ease-out"
  >
    <div class="container mx-auto">
      <div class="md:flex md:justify-between">
        <div class="mb-6 md:mb-0 2xl:block xl:block lg:block md:block sm:block hidden">
          <a
            class="flex-none flex justify-center items-center cursor-pointer"
            onclick="location.href='/';"
          >
            <img
              class="w-14 h-14 mr-2 scale-75"
              src="~@/assets/main.png"
              alt="logo"
            />
            <p class="text-xs pl-1 uppercase dark:text-lime-400">
              {{ $t("navbar.logo-text-part-1") }} <br />{{
                $t("navbar.logo-text-part-2")
              }}
            </p>
          </a>
        </div>
        <div
          class="grid grid-cols-2 text-end gap-4 2xl:text-base xl:text-base text-xs"
        >
          <div>
            <ul class="text-white text-start dark:text-lime-400">
              <li class="mb-4">
                <button
                  onclick="location.href='/contacts';"
                  class="hover:underline"
                >
                  {{ $t("home-page.contacts") }}
                </button>
              </li>
              <li>
                <button
                  onclick="location.href='/vacancy';"
                  class="hover:underline disabled"
                >
                  {{ $t("navbar.vacancy") }}
                </button>
              </li>
            </ul>
          </div>
          <div>
            <ul class="text-white text-end dark:text-lime-400">
              <li class="mb-4">
                <button
                  onclick="location.href='/usefulLinks';"
                  class="hover:underline"
                >
                  {{ $t("footer.useful-links") }}
                </button>
              </li>
              <li>
                <button
                  onclick="location.href='/siteMap';"
                  class="hover:underline"
                >
                  {{ $t("footer.footer-map") }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-lime-400" />
      <a
        :title="$t('footer.common')"
        class="2xl:block xl:block lg:block md:block sm:block hidden"
        rel="license"
        href="http://creativecommons.org/licenses/by-nd/4.0/"
        ><img
          alt="Лицензия Creative Commons"
          style="border-width: 0"
          src="https://i.creativecommons.org/l/by-nd/4.0/80x15.png"
      /></a>
      <p class="2xl:w-96 xl:w-96 lg:w-96 md:w-96 sm:w-96 text-sm my-4 2xl:block xl:block lg:block md:block sm:block hidden">
        {{ $t('rebrand.common') }} <span onclick="location.href='https://creativecommons.org/licenses/by/4.0/'" class="cursor-pointer hover:text-black transition-all ease-out">Creative Commons Attribution 4.0 International.</span>
      </p>
      <div class="flex-col text-center">
        <div class="flex items-center justify-center space-x-6">
          <a
            target="_blank"
            href="https://m.facebook.com/deponet.uz"
            class="text-white hover:text-gray-900 dark:text-lime-400 transition-all ease-out"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <a
            target="_blank"
            href="https://instagram.com/uz.csd?igshid=NTc4MTIwNjQ2YQ=="
            class="text-white hover:text-gray-900 dark:text-lime-400 transition-all ease-out"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <a
            target="_blank"
            href="https://t.me/s/UzCSD/"
            class="text-white hover:text-gray-900 dark:text-lime-400 transition-all ease-out"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              xml:space="preserve"
            >
              <path
                id="telegram-5"
                d="M12,0c-6.627,0 -12,5.373 -12,12c0,6.627 5.373,12 12,12c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12Zm0,2c5.514,0 10,4.486 10,10c0,5.514 -4.486,10 -10,10c-5.514,0 -10,-4.486 -10,-10c0,-5.514 4.486,-10 10,-10Zm2.692,14.889c0.161,0.115 0.368,0.143 0.553,0.073c0.185,-0.07 0.322,-0.228 0.362,-0.42c0.435,-2.042 1.489,-7.211 1.884,-9.068c0.03,-0.14 -0.019,-0.285 -0.129,-0.379c-0.11,-0.093 -0.263,-0.12 -0.399,-0.07c-2.096,0.776 -8.553,3.198 -11.192,4.175c-0.168,0.062 -0.277,0.223 -0.271,0.4c0.006,0.177 0.125,0.33 0.296,0.381c1.184,0.354 2.738,0.847 2.738,0.847c0,0 0.725,2.193 1.104,3.308c0.047,0.139 0.157,0.25 0.301,0.287c0.145,0.038 0.298,-0.001 0.406,-0.103c0.608,-0.574 1.548,-1.461 1.548,-1.461c0,0 1.786,1.309 2.799,2.03Zm-5.505,-4.338l0.84,2.769l0.186,-1.754c0,0 3.243,-2.925 5.092,-4.593c0.055,-0.048 0.062,-0.13 0.017,-0.188c-0.045,-0.057 -0.126,-0.071 -0.188,-0.032c-2.143,1.368 -5.947,3.798 -5.947,3.798Z"
              />
            </svg>
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/@uzcsdmarkaziydepozitariy_695"
            class="text-white hover:text-gray-900 dark:text-lime-400 transition-all ease-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-youtube"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"
              ></path>
            </svg>
          </a>
          <a
            target="_blank"
            href="https://uz.linkedin.com/company/uzcsd?original_referer=https%3A%2F%2Fwww.google.com%2F"
            class="text-white hover:text-gray-900 dark:text-lime-400 transition-all ease-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-linkedin"
              viewBox="0 0 16 16"
            >
              <path
                d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
              ></path>
            </svg>
          </a>
          <a
            target="_blank"
            href="https://datastudio.google.com/reporting/1326022b-c169-4b94-9c86-4680e55cf874/page/1M?s=sk6SZ4WHY54"
            class="text-white hover:text-gray-900 dark:hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-5 h-5"
            >
              <path
                class="dark:text-lime-400"
                d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"
              />
            </svg>
          </a>
        </div>
        <div class="pt-2">
          <span class="text-xs text-white dark:text-lime-400"
            >© 2024
            <a @click="this.$router.push('/')" class="hover:underline cursor-pointer"
              >The Central Securities Depository</a
            >. All Rights Reserved.
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>
