<template>
  <div @click="redirect(this.path)"
      class="border border-gray-400 dark:bg-gray-900/25 dark:border-lime-500 dark:text-lime-500 dark:hover:bg-gray-900 cursor-pointer hover:border-blue-500 hover:shadow transition-all ease-out hover:bg-blue-50 flex-col h-full items-center p-5">
    <img :src="image" alt="image" class="w-[90px] h-[90px] dark:-hue-rotate-60 dark:contrast-125">
    <h2 class="2xl:text-2xl xl:text-2xl lg:text-xl font-bold text-blue-500 dark:text-white">{{ header }}</h2>
    <h3 class="">{{ description }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    path: {
      type: String,
      required: false
    }
  },
  methods: {
    redirect(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>