<template>
  <div class="main h-mainPng bg-no-repeat bg-center">
    <div class="container mx-auto text-white text-center dark:text-lime-400">
      <div class="pt-mainPt text-4xl font-black mb-1">
        {{ $t("navbar.interactive") }}
      </div>
      <div>
        {{ $t("navbar.interactive-intro") }}
      </div>
      <div
        class="h-14 bg-sky-600 mt-24 flex justify-start items-center 2xl:mt-24 xl:mt-24 lg:mt-14 md:mt-14 px-8 2xl:visible xl:visible lg:visible md:visible sm:invisible invisible rounded-md absolute container dark:bg-gray-900"
      >
        <nav class="flex px-5 py-3 text-white" aria-label="Breadcrumb">
          <ol
            class="inline-flex items-center space-x-1 md:space-x-10 dark:text-lime-400"
          >
            <li class="inline-flex items-center">
              <router-link
                :to="{ path: '/' }"
                class="inline-flex items-center text-md font-medium hover:text-slate-300"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                {{ $t("ceo-message.main-screen") }}
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-white dark:text-lime-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="ml-1 text-md font-medium">
                  {{ $t("navbar.interactive") }}</span
                >
              </div>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-white dark:text-lime-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="ml-1 text-md font-medium">{{
                  $t("navbar.template-blanks")
                }}</span>
              </div>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="dark:text-lime-400 dark:bg-gray-777">
    <div class="container mx-auto">
      <div class="flex justify-center items-end pt-20">
        <div class="flex flex-col">
          <div
            class="flex 2xl:justify-end xl:justify-end lg:justify-end md:justify-end sm:justify-end justify-end"
          >
            <div class="w-3 h-1 bg-gray-400"></div>
            <div class="w-5 h-1 bg-green-400"></div>
            <div class="w-3 h-1 bg-sky-400"></div>
          </div>
          <div
            class="2xl:text-2xl xl:text-2xl lg:text-2xl md:text-2xl sm:text-xl text-xl font-bold mb-14 text-center"
          >
            {{ $t("navbar.template-blanks") }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mx-auto mb-10">
    <div
      id="accordion-open"
      data-accordion="close"
      data-active-classes="pulse bg-sky-600 text-white text-bold"
    >
      <h2 id="accordion-open-heading-1" class="">
        <button
          type="button"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-sky-300 border-b-0 rounded-t-md"
          data-accordion-target="#accordion-open-body-1"
          aria-expanded="false"
          aria-controls="accordion-open-body-1"
        >
          <span class="flex items-center"
            ><path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            ></path
            >{{ $t("contract.body-1") }}</span
          >
          <svg
            data-accordion-icon
            class="w-6 h-6 rotate-180 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </h2>
      <div
        id="accordion-open-body-1"
        class="hidden"
        aria-labelledby="accordion-open-heading-1"
      >
        <div
          class="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900"
        >
          <div class="container mx-auto py-5">
            <iframe
              id="pdf_view"
              class="w-full h-screen border"
              height="100%"
              width="100%"
              :src="'lib/pdfjs-3.1.81-dist/web/viewer.html?file=account_depot_card.pdf'"
            ></iframe>
          </div>
        </div>
      </div>

      <h2 id="accordion-open-heading-2">
        <button
          type="button"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-sky-300 focus:ring-4 focus:ring-sky-100 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-open-body-2"
          aria-expanded="false"
          aria-controls="accordion-open-body-2"
        >
          <span class="flex items-center"
            ><path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            ></path
            >{{ $t("contract.body-2") }}</span
          >
          <svg
            data-accordion-icon
            class="w-6 h-6 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </h2>
      <div
        id="accordion-open-body-2"
        class="hidden"
        aria-labelledby="accordion-open-heading-2"
      >
        <div
          class="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700"
        >
          <div class="container mx-auto py-5">
            <iframe
              id="pdf_view"
              class="w-full h-screen border"
              height="100%"
              width="100%"
              :src="'lib/pdfjs-3.1.81-dist/web/viewer.html?file=account_statement_ru.pdf'"
            ></iframe>
          </div>
        </div>
      </div>

      <h2 id="accordion-open-heading-3">
        <button
          type="button"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-sky-300 focus:ring-4 focus:ring-sky-100 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-open-body-3"
          aria-expanded="false"
          aria-controls="accordion-open-body-3"
        >
          <span class="flex items-center"
            ><path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            ></path
            >{{ $t("contract.body-3") }}</span
          >
          <svg
            data-accordion-icon
            class="w-6 h-6 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </h2>
      <div
        id="accordion-open-body-3"
        class="hidden"
        aria-labelledby="accordion-open-heading-3"
      >
        <div
          class="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700"
        >
          <div class="container mx-auto py-5">
            <iframe
              id="pdf_view"
              class="w-full h-screen border"
              height="100%"
              width="100%"
              :src="'lib/pdfjs-3.1.81-dist/web/viewer.html?file=death_account_statement_ru.pdf'"
            ></iframe>
          </div>
        </div>
      </div>

      <h2 id="accordion-open-heading-4">
        <button
          type="button"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-sky-300 focus:ring-4 focus:ring-sky-100 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-open-body-4"
          aria-expanded="false"
          aria-controls="accordion-open-body-4"
        >
          <span class="flex items-center"
            ><path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            ></path
            >{{ $t("contract.body-4") }}</span
          >
          <svg
            data-accordion-icon
            class="w-6 h-6 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </h2>
      <div
        id="accordion-open-body-4"
        class="hidden"
        aria-labelledby="accordion-open-heading-4"
      >
        <div
          class="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700"
        >
          <div class="container mx-auto py-5">
            <iframe
              id="pdf_view"
              class="w-full h-screen border"
              height="100%"
              width="100%"
              :src="'lib/pdfjs-3.1.81-dist/web/viewer.html?file=individual_entity.pdf'"
            ></iframe>
          </div>
        </div>
      </div>

      <h2 id="accordion-open-heading-5">
        <button
          type="button"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-sky-300 focus:ring-4 focus:ring-sky-100 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-open-body-5"
          aria-expanded="false"
          aria-controls="accordion-open-body-5"
        >
          <span class="flex items-center"
            ><path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            ></path
            >{{ $t("contract.body-5") }}</span
          >
          <svg
            data-accordion-icon
            class="w-6 h-6 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </h2>
      <div
        id="accordion-open-body-5"
        class="hidden"
        aria-labelledby="accordion-open-heading-5"
      >
        <div
          class="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700"
        >
          <div class="container mx-auto py-5">
            <iframe
              id="pdf_view"
              class="w-full h-screen border"
              height="100%"
              width="100%"
              :src="'lib/pdfjs-3.1.81-dist/web/viewer.html?file=issue_account_statement.pdf'"
            ></iframe>
          </div>
        </div>
      </div>

      <h2 id="accordion-open-heading-6">
        <button
          type="button"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-sky-300 focus:ring-4 focus:ring-sky-100 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-open-body-6"
          aria-expanded="false"
          aria-controls="accordion-open-body-6"
        >
          <span class="flex items-center"
            ><path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            ></path
            >{{ $t("contract.body-6") }}</span
          >
          <svg
            data-accordion-icon
            class="w-6 h-6 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </h2>
      <div
        id="accordion-open-body-6"
        class="hidden"
        aria-labelledby="accordion-open-heading-6"
      >
        <div
          class="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700"
        >
          <div class="container mx-auto py-6">
            <iframe
              id="pdf_view"
              class="w-full h-screen border"
              height="100%"
              width="100%"
              :src="'lib/pdfjs-3.1.81-dist/web/viewer.html?file=legal_entity.pdf'"
            ></iframe>
          </div>
        </div>
      </div>

      <h2 id="accordion-open-heading-7">
        <button
          type="button"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-sky-300 focus:ring-4 focus:ring-sky-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-open-body-7"
          aria-expanded="false"
          aria-controls="accordion-open-body-7"
        >
          <span class="flex items-center"
            ><path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            ></path
            >{{ $t("contract.body-7") }}</span
          >
          <svg
            data-accordion-icon
            class="w-6 h-6 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </h2>
      <div
        id="accordion-open-body-7"
        class="hidden"
        aria-labelledby="accordion-open-heading-7"
      >
        <div
          class="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700"
        >
          <div class="container mx-auto py-6">
            <iframe
              id="pdf_view"
              class="w-full h-screen border"
              height="100%"
              width="100%"
              :src="'lib/pdfjs-3.1.81-dist/web/viewer.html?file=placement_issues.pdf'"
            ></iframe>
          </div>
        </div>
      </div>

      <h2 id="accordion-open-heading-8">
        <button
          type="button"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-sky-300 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-open-body-8"
          aria-expanded="false"
          aria-controls="accordion-open-body-8"
        >
          <span class="flex items-center"
            ><path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            ></path
            >{{ $t("contract.body-6") }}</span
          >
          <svg
            data-accordion-icon
            class="w-6 h-6 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </h2>
      <div
        id="accordion-open-body-8"
        class="hidden"
        aria-labelledby="accordion-open-heading-8"
      >
        <div
          class="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700"
        >
          <div class="container mx-auto py-6">
            <iframe
              id="pdf_view"
              class="w-full h-screen border"
              height="100%"
              width="100%"
              :src="'lib/pdfjs-3.1.81-dist/web/viewer.html?file=state_share_request.pdf'"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
