<template>1</template>
<!--
<template>
  <div class="main h-mainPng bg-no-repeat bg-center">
    <div class="container mx-auto text-white text-center">
      <div
          class="pt-mainPt 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-4xl sm:text-2xl text-2xl font-black mb-1"></div>
      <div></div>
      <div
          class="h-14 bg-sky-600 2xl:mt-40 xl:mt-40 lg:mt-40 md:mt-40 flex justify-start items-center px-8 2xl:block xl:block lg:block md:block hidden rounded-md ">
      </div>
    </div>

  </div>
  <div class="container mx-auto">
    <div class="flex justify-center items-end mt-20">
      <div class="flex flex-col">
        <div class="flex 2xl:justify-end xl:justify-end lg:justify-end md:justify-end sm:justify-end justify-end ">
          <div class="w-3 h-1 bg-gray-400"></div>
          <div class="w-5 h-1 bg-green-400"></div>
          <div class="w-3 h-1 bg-sky-400"></div>
        </div>
        <div class="2xl:text-2xl xl:text-2xl lg:text-2xl md:text-2xl sm:text-xl text-xl font-bold mb-14 text-center">
          {{ $t('developing.developing') }}
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center mt-10 mb-10">
    <img src="~@/assets/developing.png">
  </div>

</template>

<script>
</script>

<style>
.main {
  background: url("@/assets/aboutUs.png");
}

* {
  font-family: "Gotham Pro", Gotham, Arial;
}
</style>-->
