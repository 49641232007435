<template>
  <div class="h-screen flex flex-col">
    <div class="">
      <NavbarPage />
    </div>
    <div class="basis-full">
      <div class="mx-auto">
        <router-view />
      </div>
    </div>
    <div class="basis-footer">
      <FooterPage />
    </div>
  </div>
</template>

<script>
import NavbarPage from "@/views/NavbarPage";
import FooterPage from "@/views/FooterPage";

export default {
  components: {
    NavbarPage,
    FooterPage,
  },
};
</script>

<style>
* {
  font-family: Gilroy;
}

@font-face {
  font-family: "Gilroy Light";
  src: local("Gilroy-Light"), url(./fonts/Gilroy-Light.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Regular"),
    url(./fonts/Gilroy-Regular.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy Medium";
  src: local("Gilroy-Medium"),
    url(./fonts/Gilroy-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy Semi Bold";
  src: local("Gilroy-Semi-Bold"), url(./fonts/Gilroy-Semibold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Bold"),
    url(./fonts/Gilroy-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy Black";
  src: local("Gilroy-Black"),
    url(./fonts/Gilroy-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

.dark {
  background-color: #464646;
}
</style>
