import { createRouter, createWebHistory } from 'vue-router'
import AffiliatesPage from '../views/AffiliatesPage.vue'
import BusinessExecutionPage from '../views/BusinessPage.vue'
import BusinessPlanPage from '../views/BusinessPlanPage.vue'
import CeoMessagePage from '../views/CeoMessagePage.vue'
import ConsultServicePage from '../views/ConsultServicePage.vue'
import ContactPage from '../views/ContactPage.vue'
import ContractPage from '../views/ContractPage.vue'
import CorporateCharterPage from '@/views/CorporateCharterPage.vue'
import DepoServicePage from '../views/DepoServicePage.vue'
import DevelopingPage from "@/views/DevelopingPage.vue";
import EssentialFactsPage from "@/views/EssentialFactsPage.vue";
import ExternalAuditPage from '../views/ExternalAuditPage.vue'
import ExecutiveBodyPage from '../views/ExecutiveBodyPage.vue'
import FinancialReportPage from '../views/FinancialReportPage.vue'
import FunctionPage from '../views/FunctionPage.vue'
import GlossaryPage from '../views/GlossaryPage'
import HomePage from '../views/HomePage'
import Headliners from '../views/HeadlinersPage'
import HistoryPage from '../views/HistoryPage.vue'
import InternalDocumentsPage from '../views/InternalDocumentsPage.vue'
import IssuersListPage from '../views/IssuersListPage.vue'
import IsinAndCfiPage from '../views/IsinAndCfiPage.vue'
import ItServicePage from '../views/ItServicePage.vue'
import KpiPage from '../views/KpiPage'
import MediaPage from '../views/MediaPage.vue'
import MembershipPage from '../views/MembershipPage.vue'
import MissionPage from '../views/MissionPage.vue'
import MSFOReportsPage from "../views/MSFOReportsPage";
import NewsDetailPage from '../views/NewsDetailPage.vue'
import NewsPage from '../views/NewsPage.vue'
import OnlineReportPage from '../views/OnlineReportPage.vue'
import OrgStructurePage from '../views/OrgStructurePage.vue'
import PartnersPage from '../views/PartnerPage.vue'
import PressReleasePage from '../views/PressReleasePage.vue'
import PublicationsPage from '../views/PublicationsPage.vue'
import PurchasesPage from '../views/PurchasesPage'
import ProfilePage from '../views/ProfilePage.vue'
import QuestionnairePage from '../views/QuestionnairePage.vue'
import ResultsPage from '../views/ResultsPage.vue'
import RegulationPage from '../views/RegulationPage.vue'
import RegulatoryDocumentPage from '../views/RegulatoryDocumentPage.vue'
import ReviewPage from '../views/ReviewPage.vue'
import SecuritiesIssuePage from '@/views/SecuritiesIssuePage.vue'
import StatisticalInformationPage from '../views/StatisticaInformationPage.vue'
import StrategyPage from '../views/StrategyPage.vue'
import StructurePage from '../views/StructurePage.vue'
import SupervisoryBoardPage from '../views/SupervisoryBoardPage.vue'
import SearchPage from '../views/SearchPage.vue'
import UsefulPage from '../views/UsefulPage.vue'
import TarifPage from '../views/TarifPage.vue'
import TechnicalSupportPage from '../views/TechnicalSupportPage.vue'
import TemplatesPage from '../views/TemplatesPage.vue'
import VacancyPage from '../views/VacancyPage.vue'
import TestingPage from '../views/TestingPage.vue'
import SiteMap from '../views/SiteMap.vue'
import DirectorGeneralsAppeal from '@/views/DirectorGeneralsAppeal.vue'
import DepartamentRegulatoryDocuments from '@/views/DepartamentRegulatoryDocuments.vue'
import NewVacancyPage from '@/views/NewVacancyPage.vue'
import CommunicationChannels from '@/views/CommunicationChannelsPage.vue'
import VacancyResults from '@/views/VacancyResultPage.vue'
import Instructions from '@/views/InstructionsPage.vue'
//import Offertas from '@/views/OffertaPage.vue'
import Offertas from '@/views/NewOffertaPage.vue'
import NewVideoPage from '../views/NewVideoPage.vue'
import VideosDetailPage from '@/views/VideosDetailPage.vue'
import ActiveTarifs from '../views/ActiveTarifPage.vue'
import Audios from '../views/AudioPage.vue'
import AudiosDetailPage from'../views/AudiosDetailPage.vue'
import Iso15022 from '../views/Iso15022Page.vue'
import Iso20022 from '../views/Iso20022Page.vue'
import PracticalActivitePage from '../views/PracticalActivitePage.vue'
import PracticalActiviteDetailPage from '../views/PracticalActiviteDetailPage.vue'
// import ExamplePages from '../views/ExamplePages.vue'



const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/affiliates',
      name: 'affiliates',
      component: AffiliatesPage
    },
    {
      path: '/businessExecution',
      name: 'businessExecution',
      component: BusinessExecutionPage
    },
    {
    path: '/businessPlan',
    name: 'businessPlan',
    component: BusinessPlanPage
    },
    {
      path: '/ceoMessage',
      name: 'ceoMessage',
      component: CeoMessagePage
    },
    {
      path: '/consultService',
      name: 'consultService',
      component: ConsultServicePage
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: ContactPage
    },
    {
      path: '/contracts',
      name: 'contracts',
      component: ContractPage
    },
    {
      path: '/corporateCharter',
      name: 'corporateCharter',
      component: CorporateCharterPage
    },
    {
      path: '/depoService',
      name: 'depoService',
      component: DepoServicePage
    },
    {
      path: '/externalAudit',
      name: 'externalAudit',
      component: ExternalAuditPage
    },
    {
      path: '/developing',
      name: 'developing',
      component: DevelopingPage
    },
    {
      path: '/essentialFacts',
      name: 'essentialFacts',
      component: EssentialFactsPage
    },
    {
      path: '/executiveBody',
      name: 'executiveBody',
      component: ExecutiveBodyPage
    },
    {
      path: '/financialReport',
      name: 'financialReport',
      component: FinancialReportPage
    },
    {
      path: '/function',
      name: 'function',
      component: FunctionPage
    },
    {
      path: '/glossary',
      name: 'glossary',
      component: GlossaryPage
    },
    {
      path: '/history',
      name: 'history',
      component: HistoryPage
    },
    {
      path: '/usefulLinks',
      name: 'usefulLinks',
      component: UsefulPage
    },
    {
      path: '/headliners',
      name: 'headliners',
      component: Headliners
    },
    {
      path: '/internalDocuments',
      name: 'internalDocuments',
      component: InternalDocumentsPage
    },
    {
      path: '/issuersList',
      name: 'issuersList',
      component: IssuersListPage
    },
    {
      path: '/isinAndcfi',
      name: 'isinAndcfi',
      component: IsinAndCfiPage
    },
    {
      path: '/itService',
      name: 'itService',
      component: ItServicePage
    },
    {
      path: '/kpi',
      name: 'kpi',
      component: KpiPage
    },
    {
      path: '/media',
      name: 'media',
      component: MediaPage
    },
    {
      path: '/msfo',
      name: 'msfo',
      component: MSFOReportsPage
    },
    {
      path: '/membership',
      name: 'membership',
      component: MembershipPage
    },
    {
      path: '/mission',
      name: 'mission',
      component: MissionPage
    },
    {
      path: '/newsdetail',
      name: 'newsdetail',
      component: NewsDetailPage
    },
    {
      path: '/news',
      name: 'news',
      component: NewsPage
    },
    {
      path: '/onlineReport',
      name: 'onlineReport',
      component: OnlineReportPage
    },
    {
      path: '/orgStructure',
      name: 'orgStructure',
      component: OrgStructurePage
    },
    {
      path: '/partners',
      name: 'partners',
      component: PartnersPage
    },
    {
      path: '/publications',
      name: 'publication',
      component: PublicationsPage
    },
    {
      path: '/pressRelease',
      name: 'pressRelease',
      component: PressReleasePage
    },
    {
      path: '/purchases',
      name: 'purchases',
      component: PurchasesPage
    },
    {
      path: '/profile',
      name: 'profile',
      component: ProfilePage
    },
    {
      path: '/questionnaire',
      name: 'questionnaire',
      component: QuestionnairePage
    },
    {
      path: '/regulation',
      name: 'regulation',
      component: RegulationPage
    },
    {
      path: '/regulatoryDocuments',
      name: 'regulatoryDocuments',
      component: RegulatoryDocumentPage
    },
    {
      path: '/review',
      name: 'review',
      component: ReviewPage
    },
    {
      path: '/results',
      name: 'results',
      component: ResultsPage
    },
    {
      path: '/securitiesIssue',
      name: 'securitiesIssue',
      component: SecuritiesIssuePage
    },
    {
      path: '/statisticalInformation',
      name: 'statisticalInformation',
      component: StatisticalInformationPage
    },
    {
      path: '/supervisoryBoard',
      name: 'supervisoryBoard',
      component: SupervisoryBoardPage
    },
    {
      path: '/strategies',
      name: 'strategies',
      component: StrategyPage
    },
    {
      path: '/structure',
      name: 'structure',
      component: StructurePage
    },
    {
      path: '/searchPage',
      name: 'searchPage',
      component: SearchPage
    },
    {
      path: '/tarif',
      name: 'tarif',
      component: TarifPage
    },
    {
      path: '/technicalSupport',
      name: 'technicalSupport',
      component: TechnicalSupportPage
    },
    {
      path: '/templates',
      name: 'templates',
      component: TemplatesPage
    },
    {
      path: '/vacancy',
      name: 'vacancy',
      component: VacancyPage
    },
    {
      path: '/newVacancy',
      name: 'newVacancy',
      component: NewVacancyPage
    },
    {
      path: '/testing',
      name: 'testing',
      component: TestingPage
    },
    {
      path: '/siteMap',
      name: 'siteMap',
      component: SiteMap
    },
    {
      path: '/directorGeneralsAppeal',
      name: 'directorGeneralsAppeal',
      component: DirectorGeneralsAppeal
    },
    {
      path: '/departamentRegulatoryDocuments',
      name: 'departamentRegulatoryDocuments',
      component: DepartamentRegulatoryDocuments
    },
    {
      path: '/communicationChannels',
      name: 'communicationChannels',
      component: CommunicationChannels
    },
    {
      path: '/vacancyResults',
      name: 'vacancyResults',
      component: VacancyResults
    },
    {
      path: '/instructions',
      name: 'instructions',
      component: Instructions
    },
    {
      path: '/offertas',
      name: 'offertas',
      component: Offertas
    },
    // {
    //   path: '/newOffertas',
    //   name: 'newOffertas',
    //   component: NewOffertas
    // },
    {
      path: '/newVideo',
      name: 'newVideo',
      component: NewVideoPage
    },
    {
      path: '/videosdetail',
      name: 'videosdetail',
      component: VideosDetailPage
    },
    {
      path: '/audios',
      name: 'audios',
      component: Audios
    },
    
    {
      path: '/audiosdetail',
      name: 'audiosdetail',
      component: AudiosDetailPage
    },
    {
      path: '/activeTarif',
      name: 'activeTarif',
      component: ActiveTarifs
    },
    {
      path: '/iso15022',
      name: 'iso15022',
      component: Iso15022
    },
    {
      path: '/iso20022',
      name: 'iso20022',
      component: Iso20022
    },
    {
      path: '/practicalactivities',
      name: 'practicalactivities',
      component: PracticalActivitePage
    },
    {
      path: '/pracdetails',
      name: 'pracdetails',
      component: PracticalActiviteDetailPage
    },
    // {
    //   path: '/examplePages',
    //   name: 'examplePages',
    //   component: ExamplePages
    // },
  ]
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router
