<template>
  <div
      class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900"
  >
    <div class="container mx-auto">
      <div
          class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center"
      >
        <h1
            class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500"
        >
          {{ $t("navbar.ceo-message") }}
        </h1>
      </div>

      <a
          onclick="window.history.back()"
          class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full"
      >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 mr-2"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
          />
        </svg>
        <span>{{ $t("table.go-back") }}</span>
      </a>

      <nav
          aria-label="Breadcrumb"
          class="2xl:block xl:block lg:block md:block hidden"
      >
        <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
          <li
              class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900"
          >
            <a
                @click="this.$router.push('/')"
                class="inline-flex items-center font-medium"
            >
              <svg
                  class="w-4 h-4 mr-2"
                  fill="white"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                ></path>
              </svg>
              <span class="hidden sm:block">{{
                  $t("ceo-message.main-screen")
                }}</span>
            </a>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg
                  class="w-6 h-6 text-gray-400"
                  fill="white"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                ></path>
              </svg>
              <span class="ml-1 font-medium md:ml-2 select-none">{{
                  $t("navbar.ceo-message")
                }}</span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div
      class="container mx-auto border m-5 rounded-xl shadow py-5 bg-slate-50/25 dark:bg-gray-900 dark:border-lime-500"
  >
    <div class="container mx-auto">
      <figure class="mx-auto max-w-screen-lg text-center px-2">
        <blockquote class="flex justify-center items-center space-x-5">
          <img
              class="rounded-lg 2xl:block xl:block lg:block md:block sm:block hidden"
              src="~@/assets/ceo.webp"
              alt=""
          />
          <div class="flex-col">
            <svg
                aria-hidden="true"
                class="mx-auto mb-3 w-12 h-12 text-blue-500 dark:text-lime-400"
                viewBox="0 0 24 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"
              />
            </svg>
            <p
                class="2xl:text-2xl xl:text-2xl lg:text-2xl md:text-2xl sm:text-lg text-lg italic font-medium text-blue-500 dark:text-lime-400"
            >
              {{ $t("ceo-message.body-text") }}
            </p>
            <figcaption class="flex justify-center items-center mt-6 space-x-3">
              <div
                  class="flex items-center divide-x-2 divide-blue-500 dark:divide-lime-400"
              >
                <cite
                    class="pr-3 font-medium text-black dark:text-white 2xl:text-base xl:text-base lg:text-base md:text-base sm:text-sm text-sm"
                >
                  <h1>{{ $t("ceo-message.director-name") }}</h1>
                </cite>
                <cite
                    class="pl-3 font-light text-blue-500 dark:text-lime-400 2xl:text-base xl:text-base lg:text-base md:text-base sm:text-sm text-sm"
                >
                  <h1>{{ $t("ceo-message.general-manager") }}</h1>
                </cite>
              </div>
            </figcaption>
          </div>
        </blockquote>
      </figure>
    </div>

    <div class="dark:bg-gray-900 dark:text-lime-400">
      <div
          class="container mx-auto 2xl:px-24 xl:px-24 lg:px-24 md:px-24 sm:px-24 px-2 text-justify mt-5 indent-10" v-html="$t('ceo-message.content')"
      >
      </div>
    </div>
  </div>
</template>
