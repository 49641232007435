<template>
  <a :href="link" target="_blank"
     class="flex flex-col py-4 text-sm bg-white text-center border-gray-400 dark:border-lime-500 dark:bg-gray-777 dark:border dark:text-lime-500 dark:hover:bg-gray-900 hover:shadow-md transition-all ease-linear cursor-pointer h-40 hover:bg-blue-50 hover:text-blue-500 px-2">
    <div class="flex flex-col items-center justify-around h-[320px]">
      <img :src="image" alt="" class="w-20">
      <h2 class="select-none text-base basis-1/5">{{ title }}</h2>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
  }
}
</script>