<template>
  <div v-if="loading">
     <LoadingState></LoadingState>
   </div>
   <div v-else
        class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900"
    >
      <div class="container mx-auto">
        <div
            class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center"
        >
          <h1
              class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500"
          >
            {{ $t("navbar.offerta") }}
          </h1>
        </div>
  
        <a
            onclick="window.history.back()"
            class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full"
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 mr-2"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            />
          </svg>
          <span>{{ $t("table.go-back") }}</span>
        </a>
  
        <nav
            aria-label="Breadcrumb"
            class="2xl:block xl:block lg:block md:block hidden"
        >
          <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
            <li
                class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900"
            >
              <a
                  @click="this.$router.push('/')"
                  class="inline-flex items-center font-medium"
              >
                <svg
                    class="w-4 h-4 mr-2"
                    fill="white"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                <span class="hidden sm:block">{{
                    $t("offerta.main-screen")
                  }}</span>
              </a>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg
                    class="w-6 h-6 text-gray-400"
                    fill="white"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="ml-1 font-medium md:ml-2 select-none">{{
                    $t("navbar.offerta")
                  }}</span>
              </div>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  
    <div
        class="container mx-auto border m-5 rounded-xl shadow py-5 bg-slate-50/25 dark:bg-gray-900 dark:border-lime-500"
    >
      <div class="container mx-auto">
        <div class="float-right w-1/12 my-1 mx-3">
               <button v-show="this.$i18n.locale == 'ru'"  @click=Download(this.$i18n.locale)><img src="../assets/img/pdf.png" width="50" alt="pdf" height="50"></button>
               <button v-show="this.$i18n.locale == 'uz'"  @click=Download(this.$i18n.locale)><img src="../assets/img/pdf.png" width="50" alt="pdf" height="50"></button>
               <button v-show="this.$i18n.locale == 'en'"  @click=Download(this.$i18n.locale)><img src="../assets/img/pdf.png" width="50" alt="pdf" height="50"></button>
       </div>
        <figure class="mx-auto max-w-screen-lg text-center px-2">
            <blockquote class="flex justify-center items-center space-x-5">
                <div class="container">
                    <p class="2xl:text-4xl xl:text-2xl lg:text-2xl md:text-2xl sm:text-lg text-lg font-extrabold text-black dark:text-lime-400">
                    {{ $t("offerta.header-text") }}
                  </p>
                  <p class="2xl:px-24 xl:px-24 lg:px-24 md:px-24 sm:px-24 px-2 mr-10 mt-3 indent-10">
                        {{ $t("offerta.header-text-bottom") }}
                  </p>
                    <figcaption class=" mt-6 space-x-1">
                <div class="flex justify-between items-center w-full divide-blue-500 dark:divide-lime-400">
                  <span class="font-medium text-black dark:text-white 2xl:text-base xl:text-base lg:text-base md:text-base sm:text-sm text-sm">
                    <h1>{{ $t("offerta.city") }}</h1>
                  </span>
                  <span class="font-light text-black dark:text-lime-400 2xl:text-base xl:text-base lg:text-base md:text-base sm:text-sm text-sm">
                    <h1>{{ $t("offerta.years") }}</h1>
                  </span>
                </div>
              </figcaption>
            </div>
          </blockquote>
        </figure>
      </div>
  
      <div class="dark:bg-gray-900 dark:text-lime-400">
        <!-- <div 
            class="container mx-auto 2xl:px-24 xl:px-24 lg:px-24 md:px-24 sm:px-24 px-2 text-justify mt-5 indent-10" v-html="$t('offerta.content')"
        > -->
        <div v-show="this.$i18n.locale == 'ru'" class="container mx-auto 2xl:px-24 xl:px-24 lg:px-24 md:px-24 sm:px-24 px-2 text-justify mt-5 indent-10" >
          <p>Настоящий документ является официальным предложением (публичной офертой,
              далее – «Оферта») АО "Центральный депозитарий ценных бумаг" (далее – Депозитарий)
              для дееспособных физических лиц-держателей банковских карт EMV (далее –
              "Пользователь") заключить договор на оказания услуг в соответствии со ст.367 и ч.2 ст.369
              Гражданского кодекса Республики Узбекистан по использованию Сервиса «UzCSD» (далее
              – "Договор"). Принятие Пользователем условий, изложенных настоящей Офертой, означает
              его полное согласие с этими условиями (акцепт Оферты). С момента принятия Оферты
              Договор считается заключенным. В случае отсутствия явных указаний в Договоре, стороны
              руководствуются действующим законодательством Республики Узбекистан и обычаями
              делового оборота. 
          </p>
          <h1 class="text-center">
            <b>1. Термины и определения</b>
          </h1>
          <p><b>Сервис «UzCSD»</b> - программный продукт, предоставляющий возможность
              Пользователю в онлайн-режиме открывать и управлять своим банковским счетом,
              открытым в Депозитарии, осуществлять переводы денежных средств через банковскую
              карту, а также выполнять другие действия, доступные в рамках функционала сервиса.
          </p>
          <p><b>Пользователь</b> – дееспособное физическое лицо, держатель банковской карты EMV.</p>
          <p><b>Банковская карта</b> - платежная карта, функционирующая в онлайн-режиме,
              эмитированная банком Республики Узбекистан и зарегистрированная в Сервисе «UzCSD».
          </p>
          <p><b>Персональные данные</b> — информация, относящаяся к определенному физическому
              лицу или дающая возможность его идентификации, предоставленная Пользователем для
              использования Сервиса «UzCSD».
          </p>
          <p><b>Обработка персональных данных</b> - выполнение одного или нескольких действий по
              сбору, систематизации, хранению, изменению, дополнению, использованию,
              предоставлению, распространению, передачу, обезличиванию и уничтожению
              персональных данных.
          </p>
          <h1 class="text-center">
            <b>2. Порядок акцепта</b>
          </h1>
          <p><b>2.1.</b> Акцепт настоящей Оферты осуществляется путём регистрации Пользователя в
              Сервисе «UzCSD». Сервис доступен на платформах iOS, Android и веб-сайте по адресу
              uzcsd.uz.
          </p>
          <p><b>2.2.</b> Пользователь, заключая настоящий Договор, даёт бессрочное согласие на
              обработку своих персональных данных Депозитарием в соответствии с требованиями
              законодательства Республики Узбекистан «О персональных данных».
              В том числе: учетные данные оплаты, номер мобильного телефона, номера банковских
              карт, ФИО, регистрационные данные, а также на другие персональные данные,
              необходимые для использования Сервисов, доступных в Сервисе «UzCSD». Пользователь
              соглашается на сбор, запись, систематизацию, хранение, уточнение, извлечение,
              использование, передачу, обезличивание, блокирование, удаление и уничтожение своих
              персональных данных, осуществляемых с использованием средств автоматизации или без
              использования таких средств, с целью исполнения настоящего Договора. 
          </p>
          <p>
            Кроме того, Пользователь разрешает передачу своих персональных данных третьим
            лицам в целях исполнения настоящего Договора.
          </p>
          <p><b>2.3.</b> Депозитарий гарантирует Пользователю принятие всех необходимых мер по
            обеспечению конфиденциальности его персональных данных в соответствии с
            законодательством Республики Узбекистан и Политикой конфиденциальности. Согласие
            Пользователя на обработку (в том числе передачу третьим лицам) его персональных данных
            является безусловным и бессрочным. Пользователь вправе отозвать свое согласие на
            обработку персональных данных в любое время в соответствии с пунктом 6.3 настоящего
            Договора.
          </p>
          <p><b>2.4.</b> Акцептуя настоящую Оферту (заключая настоящий Договор), Пользователь
            соглашается на проведение его надлежащей проверки и идентификации его личности со
            стороны Депозитария при использовании Сервисов и услуг, предоставляемых третьими
            лицами (организациями) через Сервис «UzCSD». Такие меры проверки и идентификации
            осуществляются в соответствии с требованиями, установленными правилами,
            инструкциями и другими нормативно-правовыми актами указанных третьих лиц
            (организаций).
          </p>
          <p><b>2.5.</b> До начала использования Сервиса «UzCSD», необходимо полностью ознакомиться
            с условиями настоящей Оферты. В случае несогласия с любой частью или всеми условиями
            Оферты, рекомендуется воздержаться от использования Сервиса «UzCSD».
          </p>
          <h1 class="text-center">
            <b>3. Предмет договора</b>
          </h1>
          <p><b>3.1.</b> Депозитарий предоставляет Пользователям возможность открывать в Депозитарии
            и у партнеров (инвестиционных посредников) счета депо и управлять своими банковскими
            счетами, открытыми в Депозитарии, с использованием Сервиса «UzCSD». 
          </p>
          <p>
            Пользователи также могут управлять своими банковскими картами, осуществлять
            ереводы и другие операции в рамках функционала Сервиса.
          </p>
          <p>
            Платежные услуги по зачислению Депозитарием денежных средств на банковскую
            карту Пользователя предоставляются платежной организацией, с которой Депозитарий
            заключил соответствующий договор.
          </p>
          <p><b>3.2.</b> Сервис «UzCSD» может включать услуги, предоставляемые Пользователям на
            платной основе. Активация таких услуг осуществляется Пользователем самостоятельно по
            желанию после ознакомления с публичной офертой, в которой содержится описание
            услуги, условия ее предоставления, стоимость использования и другая необходимая
            информация.
          </p>
          <p><b>3.3.</b> Обработка предоставленных Пользователем персональных данных Депозитарием
            осуществляется с целью:
          </p>
          <p>
            <ul class="list-disc list-inside">
              <li>
                Надлежащего выполнения обязательств Депозитария, принятых в рамках заключенных соглашений или договоров с Пользователем,
                а также соглашений с Партнерами Депозитария, в том числе в целях проведения идентификации или упрощенной идентификации 
                Пользователя и предоставления ему возможностей использования всех Сервисов «UzCSD»;
              </li>
              <li>
                Осуществления связи с Пользователем, включая направление уведомлений,
                запросов и информации об использовании сервисов Депозитария или оказании им
                услуг, а также обработка запросов и заявок от Пользователя, в том числе с
                последующей передачей запросов и заявок контрагентам или партнерам
                Депозитария;
              </li>
              <li>
                Улучшения качества и удобства использования сервисов Депозитария, разработки
                новых сервисов и предоставления Пользователю персонализированных сервисов;
              </li>
              <li>
                Проведения статистических и иных исследований, на основе обезличенных данных;
              </li>
              <li>
                Распространения публикаций от контрагентов или партнеров Депозитария, включая
                предложения участия в покупке финансовых инструментов на рынке капитала,
                проводимых партнерами или контрагентами Депозитария, а также распространения
                рекламно-информационных материалов контрагентов или партнеров Депозитария,
                связанных с финансовыми инструментами рынка капитала, через различные
                средства связи и таргетирование рекламы;
              </li>
              <li>
                Предотвращения незаконных действий, противоречащих законодательству или
                соглашений, с использованием Сервиса «UzCSD»;
              </li>
              <li>
                Предоставления статистических данных Пользователям на основе данных рынка
                капитала. 
              </li>
            </ul>
          </p>
          <p><b>3.4.</b> Нажатие на соответствующие кнопки в интерфейсе Сервиса «UzCSD» (например,
                "перевести", "оплатить" и другие) рассматривается как распоряжение Пользователя, а
                также согласие на зачисление и списание суммы, указанной в распоряжении, в
                соответствующем окне интерфейса Сервиса.
          </p>
          <p><b>3.5.</b> Сервис «UzCSD» может содержать сервисы, которые могут предоставляться
                Пользователям на платной основе. Активация таких сервисов производится Пользователем
                самостоятельно, по желанию, после ознакомления с публичной офертой, в которой указаны
                описание сервиса, условия его предоставления, стоимость использования и другая
                информация.
          </p>
          <h1 class="text-center">
            <b>4. Обязательства сторон</b>
          </h1>
          <p><b>4.1.</b> Депозитарий обязуется:</p>
          <p>
            <ul class="list-disc list-inside">
              <li>Обеспечить непрерывную работу Сервиса «UzCSD», за исключением случаев, когда
                сбои в функционировании сервиса происходят по вине третьих лиц;
              </li>
              <li>
                Принимать правовые, организационные и технические меры по защите
                персональных данных, обеспечивающие их целостность и сохранность, а также 
                соблюдение конфиденциальности и предотвращение незаконной обработки персональных данных;
              </li>
              <li>Своевременно информировать Пользователя о новостях, касающихся Депозитария
                и его Партнеров, выступающих поставщиками услуг, доступных для оплаты через
                Сервис «UzCSD», а также об изменениях и обновлениях, внесенных в Сервис;
              </li>
              <li>Направлять уведомления государственного органа по регулированию рынка
                капитала или Центрального банка Республики Узбекистан, о любых событиях,
                которые необходимо довести до владельцев финансовых инструментов или
                Пользователей Сервиса.
              </li>
            </ul>
          </p>
          <h1 class="text-center">
            <b>4.2. Депозитарий имеет право:</b>
          </h1>
          <p>
            <ul class="list-disc list-inside">
              <li>Получать от Пользователя информацию в части идентификации и надлежащей
                  проверки Пользователя и операций, совершаемых им с целью принятия соблюдения
                  законодательства Республики Узбекистан по противодействию легализации
                  доходов, полученных от преступной деятельности, финансированию терроризма и
                  финансированию распространения оружия массового уничтожения;               
              </li>
              <li>Требовать от клиента предоставление информации об источниках денежных
                средств, используемых при проведении операции;
              </li>
              <li>В одностороннем порядке вносить изменения в настоящую Оферту путем
                направления уведомления Пользователю об этих изменениях через:
                <ul>
                  <li> - официальный сайт uzcsd.uz;</li>
                  <li> - информационные сообщения;</li>
                  <li> - интерфейс Сервиса;</li>
                  <li> - SMS-оповещения.</li>
                </ul>
              </li>
            </ul>
          </p>
          <h1 class="text-center">
            <b>4.3. Пользователь обязуется:</b>
          </h1>
          <p>
            <ul class="list-disc list-inside">
              <li>Соблюдать условия настоящей Оферты;</li>
              <li>Самостоятельно обеспечивать информационную безопасность устройства, с
                помощью которого осуществляется доступ в Сервис;
              </li>
              <li>Используя Сервис «UzCSD», подтверждать и гарантировать свою дееспособность, а
                также отказываться от использования Сервиса «UzCSD» в целях мошенничества или
                иных противоправных действий.
              </li>
            </ul>
          </p>
          <h1 class="text-center">
            <b>4.4. Пользователь не вправе:</b>
          </h1>
          <p>
            <ul class="list-disc list-inside">
              <li>Передавать свои права и обязанности по настоящему Договору третьим лицам без
                предварительного письменного согласия со стороны Депозитария запрещается.
                Любая попытка такой передачи будет признана недействительной;
              </li>
              <li>Пользователь принимает на себя всю ответственность при передаче любой
                информации, связанной с предоставлением доступа третьим лицам к личному
                аккаунту в Сервисе «UzCSD», включая коды для подтверждения совершаемых
                операций, номер мобильного телефона, а также номера и срок действия банковских
                карт.
              </li>
            </ul>
          </p>
          <h1 class="text-center">
            <b>5. Стоимость оказываемых услуг и порядок расчетов</b>
          </h1>
          <p><b>5.1.</b> Стоимость предоставляемых услуг для Пользователя составляет:</p>
          <p>
            <ul class="list-disc list-inside">
              <li>
                Дистанционное открытие счета депо (с использованием системы KYC/AML, WorldCheck) в Депозитарии и у партнеров Депозитария – <b>0,05% от БРВ.</b>
              </li>
              <li>
                Дистанционное открытие одного банковского счета в Депозитарии – <b>Бесплатно;</b>
              </li>
              <li>
                Пополнение денежного счета инвестиционного посредника от клиента – <b>0,7% от суммы перевода;</b>
              </li>
              <li>
                Перевод средств с денежного счета физического лица-клиента на его банковскую карту – <b>0,7% от суммы перевода;</b>
              </li>
              <li>
                Перевод средств с денежного счета у инвестиционного посредника на банковскую карту непосредственно через Сервис «UzCSD» – <b>0,7% от суммы перевода;</b>
              </li>
              <li>
                Получение публикаций партнеров Депозитария связанных с финансовыми инструментами рынка капитала – <b>Бесплатно;</b>
              </li>
            </ul>
        </p>
        <p><b>5.2.</b> В соответствии с условиями настоящей Оферты, Пользователь обязуется уплатить
          Депозитарию денежную сумму за выполненные работы, в зависимости от предоставленной
          услуги, согласно тарифам, изложенным в пункте 5.1. 
        </p>
        <p>
          Услуга по переводу средств с денежного счета у инвестиционного посредника на
          банковскую карту осуществляется платежной организацией, с которой у Депозитария
          заключен соответствующий договор. 
        </p>
        <p><b>5.3.</b> Сумма за предоставленную услугу сервисом «UzCSD» будет удержана
          Депозитарием в момент проведения операции.
        </p>
        <h1 class="text-center">
            <b>6. Ответственность сторон</b>
        </h1>
        <p><b>6.1.</b> Стороны несут ответственность за неисполнение или ненадлежащее исполнение
          условий настоящего Договора в соответствии с законодательством Республики Узбекистан.
        </p>
        <p><b>6.2.</b> Пользователь несет ответственность за любые убытки или иные негативные
          последствия, возникшие у него в результате неисполнения или ненадлежащего исполнения
          обязанностей по настоящему Договору.
        </p>
        <p><b>6.3.</b> Пользователь несет ответственность за любые убытки или иные негативные
          последствия, возникшие у него в результате неисполнения или ненадлежащего исполнения
          обязанностей по настоящему Договору.
        </p>
        <p><b>6.4.</b> Депозитарий не несет ответственности за ненадлежащее исполнение условий
          Договора, если такое неисполнение явилось следствием неисполнения условий Договора
          Пользователем.
        </p>
        <p><b>6.5.</b> Депозитарий не несет ответственности за любые убытки, возникшие у
          Пользователя, в связи с тем, что Пользователь не ознакомился и (или) не своевременно
          ознакомился с условиями настоящей Оферты, а также с офертами платных сервисов при
          активации их в Сервисе «UzCSD».</p>
        <p><b>6.6.</b> Депозитарий не несет ответственности за ненадлежащее исполнение условий
          Договора, если такое неисполнение вызвано обстоятельствами, находящимися вне сферы
          контроля Депозитария. Сфера контроля Депозитария ограничивается только
          работоспособностью Сервиса «UzCSD» и не включает ответственность за сбои, вызванные
          действиями третьих лиц или запретами и ограничениями, наложенными органами
          государственной или судебной власти.</p>
        <p><b>6.7.</b> Весь информационный контент Сервиса «UzCSD», включая контент, программное
          обеспечение, функционал, материалы и информацию, предоставляемую в связи с запросом
          или доступную через Сервис «UzCSD», предоставляется "как есть", в неизменом виде, без
          каких-либо изменений и дополнений.
          </p>
        <p><b>6.8.</b> Депозитарий не предоставляет никаких заверений или гарантий относительно
          услуг, контента, материалов, информации и функционала, доступного через Сервис
          «UzCSD», или в отношении любых нарушений защиты, связанных с передачей
          конфиденциальной информации через Сервиса «UzCSD» в случаях использования сервиса
          способами, не предусмотренными настоящим Договором.</p>
        <p><b>6.9.</b> Депозитарий не несет ответственности перед Пользователем или любой третьей
          стороной за любые убытки или потери, возникшие в любых обстоятельствах, включая, но
          не ограничиваясь, убытками от потери прибыли, вред репутации или другими видами
          убытков, связанными с приобретением Пользователем услуг у контрагентов или партнеров
          Депозитария через Сервис «UzCSD», а также за любые услуги, информацию, контент,
          приобретенные, полученные или оплаченные через Сервис «UzCSD».</p>
        <p><b>6.10.</b> Каждая Сторона признает, что другая Сторона заключает настоящий Договор,
          признавая ограничения ответственности, установленные в настоящем Договоре, и что эти
          ограничения являются необходимой основой договоренностей между сторонами, при этом
          ни одна из Сторон не несет ответственности за невыполнение обязательств или задержку,
          вызванную какими-либо условиями, находящимися вне разумного контроля такой стороны,
          включая такие обстоятельства, как деятельность правительства или террористические акты,
          землетрясения, пожары, наводнения или другие стихийные бедствия, ситуации, связанные
          с условиями труда, сбои питания и нарушения Интернет-соединения, но не ограничиваясь
          данными обстоятельствами. </p>
          <h1 class="text-center">
            <b>7. Расторжение договора</b>
          </h1>
          <p><b>7.1.</b> Пользователь вправе в любой момент отказаться от исполнения Договора, удалив
            свой аккаунт в Сервисе «UzCSD».</p>
          <p><b>7.2.</b> Договор считается расторгнутым немедленно после удаления аккаунта.
            Расторжение данного Договора не означает прекращения действия Договоров по платным
            Сервисам, если такие Сервисы были активированы.</p>
          <p><b>7.3.</b> Пользователь вправе отозвать свое согласие на обработку персональных данных,
            отправив письмо на электронную почту по адресу, указанному в разделе 9 Договора.
          </p>
          <h1 class="text-center">
            <b>8. Порядок рассмотрения разногласий</b>
          </h1>
          <p><b>8.1.</b> Любой спор, возникший из настоящего Договора, должен быть разрешен мирным
            путем или через претензионный порядок на основании письменного обращения
            Пользователя. Сроки рассмотрения обращения и требования к его содержанию
            определяются действующим законодательством Республики Узбекистан.</p>
          <p><b>8.2.</b> В случае, если возникший спор не урегулируется в претензионном порядке, он
            будет рассмотрен в подведомственном суде по месту нахождения ответчика в соответствии
            с действующим законодательством Республики Узбекистан.</p>
          <p><b>8.3.</b>Претензионная работа с Пользователями и услуги технической поддержки
            Пользователям предоставляются исключительно путем приема запросов, их обработки и
            направления ответов на поступившие запросы. Запросы Пользователей принимаются по
            электронной почте или по телефону согласно информации, указанной в разделе 9 Договора.</p>
          <h1 class="text-center">
            <b>9. Прочие условия</b>
          </h1>
          <p><b>9.1.</b> Во всем, что не урегулировано непосредственно настоящим Договором, стороны
            руководствуются действующим законодательством Республики Узбекистан и обычаями
            делового оборота.</p>
          <p><b>9.2.</b>Подтверждая своё безоговорочное согласие со всеми условиями настоящего
            Договора, Пользователь принимает настоящую Оферту и обязуется ее исполнять.
          </p>
          <h1 class="text-center">
            <b>10. Реквизиты АО «Центральный депозитарий ценных бумаг»</b>
          </h1>
          <p>АО "Центральный депозитарий ценных бумаг"</p>
          <p>Адрес: г. Ташкент, Мирзо-Улугбекский район, проспект Мустакиллик, 107.</p>
          <p>ИНН: 203021987.</p>
          <p>НДС: 326020005461.</p>
          <p>Р/С: 20208000804014949001.</p>
          <p>МФО: 01101.</p>
          <p>ОКЭД: 66190.</p>
          <p>Адрес эл. почты: info@uzcsd.uz.</p>
          <p>Тел: +99871 2110909 (внут:172).</p>
        </div>
        <div v-show="this.$i18n.locale == 'uz'" class="container mx-auto 2xl:px-24 xl:px-24 lg:px-24 md:px-24 sm:px-24 px-2 text-justify mt-5 indent-10" >
          <p>Ушбу ҳужжат “Қимматли қоғозлар Марказий депозитарийси” АЖнинг (кейинги
            ўринларда – Депозитарий) Ўзбекистон Республикаси Фуқаролик кодексининг 367-моддаси
            ва 369-моддасининг иккинчи қисмига мувофиқ «UzCSD» Хизматидан фойдаланиш бўйича
            хизмат кўрсатиш шартномасини (кейинги ўринларда – Шартнома) тузиш юзасидан
            лаёқатли бўлган EMV банк картаси эгаси бўлган жисмоний шахслар (кейинги ўринларда –
            “Фойдаланувчи”) учун расмий таклифи (оммавий офертаси) ҳисобланади.
            Фойдаланувчининг ушбу Офертада кўрсатилган шартларни қабул қилиши, унинг ушбу
            шартлар (оферта акцепти) билан тўлиқ келишувини англатади. Оферта қабул қилинган
            пайтдан бошлаб, шартнома тузилган ҳисобланади. Шартномада аниқ кўрсатмалар мавжуд
            бўлмаган тақдирда, томонлар Ўзбекистон Республикасининг амалдаги қонунчилигига ва
            ишбилармонлик муомаласи анъаналарига амал қиладилар.
          </p>
          <h1 class="text-center">
            <b>1. Атамалар ва таърифлар</b>
          </h1>
          <p><b>«UzCSD» Хизмати</b> - Фойдаланувчига Депозитарийда ўз банк ҳисобварағини онлайн
            режимда очиш ва бошқариш, банк картаси орқали пул маблағларини ўтказиш, шунингдек
            сервис функционали доирасида мавжуд бўлган бошқа амалларни бажариш имконини
            берадиган дастурий маҳсулот.
          </p>
          <p><b>Фойдаланувчи</b> – муомалага лаёқатли жисмоний шахс, EMV банк картаси эгаси.</p>
          <p><b>Банк картаси</b> - бу Ўзбекистон Республикаси банки томонидан чиқарилган ва
            «UzCSD» Хизматида рўйхатдан ўтказилган онлайн режимда ишлайдиган тўлов картаси.
          </p>
          <p><b>Шахсга доир маълумотлар</b> — «UzCSD» Хизматидан фойдаланиш учун Фойдаланувчи
            томонидан тақдим этиладиган муайян жисмоний шахсга тегишли бўлган ёки унинг
            идентификация қилиш имконини берадиган ахборот.
          </p>
          <p><b>Шахсга доир маълумотларга ишлов бериш</b> - шахсга доир маълумотларни йиғиш,
            тизимлаштириш, сақлаш, ўзгартириш, тўлдириш, улардан фойдаланиш, уларни бериш,
            тарқатиш, узатиш, эгасизлантириш ва йўқ қилиш бўйича бир ҳаракатни ёки ҳаракатлар
            мажмуини амалга ошириш.
          </p>
          <h1 class="text-center">
            <b>2. Акцепт тартиби</b>
          </h1>
          <p><b>2.1.</b> Ушбу Офертанинг акцепти Фойдаланувчини «UzCSD» Хизматида рўйхатдан
            ўтказиш йўли билан амалга оширилади. Ушбу сервисга iOS, Android платформалари ҳамда
            uzcsd.uz манзилидаги веб-сайт орқали кириш мумкин.
          </p>
          <p><b>2.2.</b> Фойдаланувчи ушбу Шартномани тузиш орқали Ўзбекистон Республикасининг
            шахсга доир маълумотлар тўғрисидаги қонун ҳужжатлари талабларига мувофиқ ўзининг
            шахсга доир маълумотларига Депозитарий томонидан ишлов беришга муддатсиз
            розилигини беради.
          </p>
          <p>
            Шу жумладан, тўловнинг ҳисоб маълумотлари, мобил телефон рақами, банк
            карталарининг рақамлари, Ф.И.Ш., рўйхатдан ўтиш билан боғлиқ маълумотлар,
            шунингдек, «UzCSD» Хизматида фойдаланиш учун қўлланиладиган бошқа шахсга доир
            маълумотлар. Фойдаланувчи ушбу Шартномани бажариш мақсадида автоматлаштириш
            воситаларидан фойдаланган ёки улардан фойдаланмаган ҳолда, амалга ошириладиган ўз
            шахсига доир маълумотларни йиғиш, қайд этиш, тизимлаштириш, сақлаш, аниқлик
            киритиш, кўчириш, фойдаланиш, бериш, шахссизлантириш, блокировка қилиш, уларни
            ўчириш ва йўқ қилишни амалга оширилишига розилик билдиради. Бундан ташқари,
            Фойдаланувчи ушбу Шартномани бажариш мақсадида ўзининг шахсга доир
            маълумотларини учинчи шахсларга тақдим этилишига розилик беради.
          </p>
          <p><b>2.3.</b> Депозитарий Фойдаланувчига Ўзбекистон Республикаси қонун ҳужжатлари ва
            Махфийлик сиёсатига мувофиқ унинг шахсга доир маълумотларининг махфийлигини
            таъминлаш учун барча зарур чоралар кўрилишини кафолатлайди. Фойдаланувчининг
            шахсга доир маълумотларини ишлов беришга (шу жумладан учинчи шахсларга беришга)
            розилиги сўзсиз ва муддатсиз ҳисобланади. Фойдаланувчи ушбу Шартноманинг 6.3-
            бандига мувофиқ исталган вақтда шахсга доир маълумотларига ишлов берилишига
            розилигини қайтариб олиш ҳуқуқига эга.

          </p>
          <p><b>2.4.</b> Фойдалунувчи ушбу Офертани қабул қилган (ушбу Шартномани тузган) ҳолда,
            «UzCSD» Хизмати орқали учинчи шахслар (ташкилотлар) томонидан тақдим этиладиган
            сервис ва хизматлардан фойдаланганда, Депозитарий томонидан ўз шахсини лозим
            даражада текшириш ва идентификациядан ўтишга розилик билдиради. Бундай текширув ва
            идентификациялаш чоралари кўрсатилган учинчи шахсларнинг (ташкилотларнинг)
            талабларига, белгиланган қоидаларга, кўрсатмаларга ва бошқа меъёрий ҳужжатларга
            мувофиқ амалга оширилади.
          </p>
          <p><b>2.5.</b> «UzCSD» Хизматидан фойдаланишдан аввал Фойдаланувчи ушбу Офертанинг
            шартлари билан тўлиқ танишиб чиқиши зарур. Офертанинг шартлари билан тўлиқ ёки
            қисман келишилмаган ҳолларда, «UzCSD» Хизматидан фойдаланмаслик тавсия этилади.
          </p>
          <h1 class="text-center">
            <b>3. Шартнома предмети</b>
          </h1>
          <p><b>3.1.</b> Депозитарий Фойдаланувчиларга «UzCSD» Хизматидан фойдаланган ҳолда
            Депозитарийда ва ҳамкорларда (инвестиция воситачиларида) депо ҳисобварақларини очиш
            ва Депозитарийда очилган ўзларининг банк ҳисобварақларини бошқариш имкониятини
            беради.
          </p>
          <p>
            Шунингдек, Фойдаланувчилар ўзларининг банк карталарини бошқаришлари, Хизмат
            функционали доирасида ўтказмалар ва бошқа операцияларни амалга оширишлари мумкин.
          </p>
          <p>
            Депозитарий томонидан Фойдаланувчининг банк картасига пул маблағларини киритиш
            учун тўлов хизматлари Депозитарий тегишли шартнома тузган тўлов ташкилоти томонидан
            тақдим этилади.
          </p>
          <p><b>3.2.</b> «UzCSD» Хизмати Фойдаланувчиларга пуллик асосда тақдим этиладиган
            хизматларни ўз ичига олиши мумкин. Бундай хизматларни фаоллаштириш Фойдаланувчи
            томонидан хизматнинг тавсифи, уни тақдим этиш шартлари, фойдаланиш қиймати ва
            бошқа зарур маълумотларни ўз ичига олган оммавий Оферта билан танишгандан сўнг
            мустақил равишда ўз ҳоҳишига кўра амалга оширилади.
          </p>
          <p><b>3.3.</b> Депозитарий томонидан Фойдаланувчи тақдим этган шахсга доир маълумотларга
            ишлов бериш қуйидаги мақсадларда амалга оширилади:
          </p>
          <p>
            <ul class="list-disc list-inside">
              <li>
                Депозитарийнинг Фойдаланувчи билан тузилган шартномалар ёки келишувлар,
                шунингдек Депозитарийнинг ҳамкорлари билан тузилган келишувлар доирасида ўз
                зиммасига олган мажбуриятларини тегишли тарзда бажариш, шу жумладан
                Фойдаланувчини идентификация қилиш ёки Фойдаланувчини соддалаштирилган
                идентификация қилиш ва унга «UzCSD» Хизматининг барча имкониятларидан
                фойдаланишни тақдим этиш мақсадида;
              </li>
              <li>
                Фойдаланувчи билан алоқа ўрнатиш, шу жумладан Депозитарий хизматларидан
                фойдаланиш ёки уларга хизмат кўрсатиш тўғрисида хабарномалар, сўровлар ва
                маълумотларни юбориш, шунингдек Фойдаланувчи томонидан берилган сўров ва
                аризаларини қайта ишлаш, шу жумладан кейинчалик сўров ва аризаларни
                контрагентларга ёки Депозитарийнинг ҳамкорларига етказиш;
              </li>
              <li>
                Депозитарий хизматларидан фойдаланишнинг қулайлиги ва сифатини ошириш, янги
                хизматларни ишлаб чиқиш ва Фойдаланувчига мослаштирилган хизматларни
                тақдим этиш;
              </li>
              <li>
                Шахссизлаштирилган маълумотлар асосида статистик ва бошқа тадқиқотлар ўтказиш;
              </li>
              <li>
                Депозитарийнинг контрагентлари ёки ҳамкорларининг ахборотларини тарқатиш, шу
                жумладан Депозитарийнинг ҳамкорлари ёки контрагентлари томонидан капитал
                бозоридаги молиявий инструментларни сотиб олишда иштирок этиш таклифларини,
                шунингдек капитал бозорининг молиявий инструментлари билан боғлиқ
                Депозитарийнинг ҳамкорлари ва контрагентларининг реклама ва ахборот
                материалларини турли хил алоқа воситалари орқали тарқатиш ва оммага тақдим
                этиш;
              </li>
              <li>
                «UzCSD» Хизматидан фойдаланган ҳолда қонун ҳужжатларига ёки келишувларга
                зид бўлган ноқонуний ҳатти-ҳаракатларнинг олдини олиш;
              </li>
              <li>
                Капитал бозори маълумотлари асосида Фойдаланувчиларга статистик
                маълумотларни тақдим этиш.
              </li>
            </ul>
          </p>
          <p><b>3.4.</b> «UzCSD» Хизмати интерфейсидаги тегишли тугмачаларни босиш (масалан,
            "ўтказиш", "тўлаш" ва бошқалар) Фойдаланувчининг топшириғи сифатида, шунингдек
            Хизмат интерфейсининг тегишли ойнасида кўрсатилган суммани кирим қилиш ва ҳисобдан
            чиқаришга розилик сифатида қабул қилинади.
          </p>
          <p><b>3.5.</b> «UzCSD» Хизматида Фойдаланувчиларга пуллик асосда тақдим этилиши мумкин
            бўлган хизматлар бўлиши мумкин. Бундай хизматларни фаоллаштириш Фойдаланувчи
            томонидан хизматнинг тавсифи, уни тақдим этиш шартлари, фойдаланиш қиймати ва
            бошқа зарур маълумотларни ўз ичига олган оммавий оферта билан танишилгандан сўнг
            мустақил равишда ўз ҳоҳишига кўра амалга оширилади.
          </p>
          <h1 class="text-center">
            <b>4. Тарафларнинг мажбуриятлари</b>
          </h1>
          <p><b>4.1.</b> Депозитарийнинг мажбуриятлари:</p>
          <p>
            <ul class="list-disc list-inside">
              <li>«UzCSD» Хизматининг узлуксиз ишлашини таъминлаш, хизматнинг ишлашидаги
                носозликлар учинчи шахслар томонидан юзага келган ҳоллар бундан мустасно;
              </li>
              <li>Шахсга доир маълумотларни ҳимоя қилиш, уларнинг яхлитлиги ва хавфсизлигини
                таъминлаш, шунингдек шахсга доир маълумотларнинг махфийлиги ва уларга
                ноқонуний ишлов беришнинг олдини олиш бўйича ҳуқуқий, ташкилий ва техник
                чораларни кўриш;
              </li>
              <li>Депозитарий ва унинг «UzCSD» Хизмати орқали тўлаш мумкин бўлган етказиб
                берувчи ҳамкорларига тегишли янгиликлар, шунингдек хизматга киритилган
                ўзгартиришлар ва янгиланишлар ҳақида Фойдаланувчини ўз вақтида хабардор
                қилиш;
              </li>
              <li>Капитал бозорини тартибга солиш бўйича ваколатли давлат органи ёки Ўзбекистон
                Республикаси Марказий банкининг молиявий инструментлар эгаларига ёки
                хизматдан фойдаланувчиларга етказилиши зарур бўлган ҳар қандай ҳодисалар
                тўғрисидаги хабарномаларини юбориш.
              </li>
            </ul>
          </p>
          <h1 class="text-center">
            <b>4.2. Депозитарийнинг ҳуқуқлари:</b>
          </h1>
          <p>
            <ul class="list-disc list-inside">
              <li>жиноий фаолиятдан олинган даромадларни легаллаштиришга, терроризмни
                молиялаштиришга ва оммавий қирғин қуролларининг тарқалишини
                молиялаштиришга қарши курашиш тўғрисидаги Ўзбекистон Республикаси қонун
                ҳужжатларига риоя этилишини таъминлаш мақсадида Фойдаланувчини
                аниқлаштириш ва уни тўғри текшириш ҳамда у томонидан амалга оширилган
                операциялар тўғрисида Фойдаланувчидан маълумот олиш;              
              </li>
              <li>Мижоздан операция ўтказилганда фойдаланилган пул маблағларининг манбалари
                тўғрисида маълумот беришни талаб қилиш;
              </li>
              <li>ушбу Офертага Фойдаланувчига қуйидагилар орқали хабарнома юбориш йули билан
                бир томонлама ўзгартиришлар киритиш:
                <ul>
                  <li> - расмий сайт uzcsd.uz ;</li>
                  <li> - ахборот билдиришномалари;</li>
                  <li> - Хизмат интерфейси;</li>
                  <li> - СМС-огоҳлантиришлар.</li>
                </ul>
              </li>
            </ul>
          </p>
          <h1 class="text-center">
            <b>4.3. Фойдаланувчининг мажбуриятлари:</b>
          </h1>
          <p>
            <ul class="list-disc list-inside">
              <li>Ушбу Оферта шартларига амал қилиш;</li>
              <li>Хизматга кириш учун фойдаланиладиган қурилманинг ахборот хавфсизлигини
                мустақил равишда таъминлаш;
              </li>
              <li>«UzCSD» Хизматидан фойдаланган ҳолда ўзининг лаёқатлилигини тасдиқлаш ва
                кафолатлаш, шунингдек фирибгарлик ёки бошқа ноқонуний хатти-ҳаракатлар
                мақсадида «UzCSD» Хизматидан фойдаланишни рад этиш.
              </li>
            </ul>
          </p>
          <h1 class="text-center">
            <b>4.4. Фойдаланувчи қуйидагиларга ҳақли эмас:</b>
          </h1>
          <p>
            <ul class="list-disc list-inside">
              <li>Ушбу Шартнома бўйича ўзининг ҳуқуқ ва мажбуриятларини Депозитарийнинг
                олдиндан ёзма розилигисиз учинчи шахсларга ўтказиш таъқиқланади. Бундай
                ўтказишларга ҳар қандай уриниш ҳақиқий эмас деб тан олинади;
              </li>
              <li>«UzCSD» Хизматидаги шахсий аккаунтига учинчи шахсларга киришини таъминлаш
                билан боғлиқ ҳар қандай маълумотни, шу жумладан амалга оширилаётган
                операцияларни тасдиқлаш учун кодларни, мобил телефон рақами, шунингдек банк
                карталарининг рақамлари ва амал қилиш муддатини тақдим этишда Фойдаланувчи
                тўлиқ жавобгарликни ўз зиммасига олади.
              </li>
            </ul>
          </p>
          <h1 class="text-center">
            <b>5. Кўрсатиладиган хизматлар нархи ва тўлов тартиби</b>
          </h1>
          <p><b>5.1.</b> Фойдаланувчига кўрсатиладиган хизматларнинг нархи қуйидагилардан иборат:</p>
          <p>
            <ul class="list-disc list-inside">
              <li>
                Депозитарийда ва Депозитарийнинг ҳамкорларида депо ҳисобварағини масофадан очиш (KYC/AML, World-Check тизимидан фойдаланган ҳолда) – <b>0,05% БҲМ.</b>
              </li>
              <li>
                Депозитарийда битта банк ҳисобварағини масофадан очиш – <b>бепул;</b>
              </li>
              <li>
                Мижоз томонидан инвестиция воситачисининг пул ҳисобварағини тўлдириш – <b>ўтказма суммасининг 0,7%;</b>
              </li>
              <li>
                Мижоз-жисмоний шахс пул ҳисобварағидан унинг банк картасига маблағлар ўтказиш – <b>ўтказма суммасининг 0,7%;</b>
              </li>
              <li>
                Инвестиция воситачисидаги пул ҳисобварағидаги маблағларни банк картасига тўғридан-тўғри «UzCSD» Хизмати орқали ўтказиш – <b>ўтказма суммасининг 0,7%;</b>
              </li>
              <li>
                Депозитарий ҳамкорларидан капитал бозорининг молиявий инструментлари билан боғлиқ нашрларни қабул қилиш – <b>бепул;</b>
              </li>
            </ul>
        </p>
        <p><b>5.2.</b> Ушбу Оферта шартларига мувофиқ, Фойдаланувчи Депозитарийга 5.1-бандда
          белгиланган тарифларга мувофиқ кўрсатилган хизматга қараб бажарилган иш учун пул
          суммасини тўлаш мажбуриятини олади.
        </p>
        <p>
          Инвестиция воситачисидаги пул ҳисобварағидан банк картасига пул маблағларини
          ўтказиш хизмати Депозитарий билан тегишли шартнома тузган тўлов ташкилоти
          томонидан амалга оширилади. 
        </p>
        <p><b>5.3.</b> «UzCSD» Хизмати томонидан тақдим этилган хизмат учун маблағ операция
          ўтказилаётган пайтда Депозитарий томонидан ушлаб қолинади.
        </p>
        <h1 class="text-center">
            <b>6. Тарафларнинг жавобгарлиги</b>
        </h1>
        <p><b>6.1.</b> Томонлар Ўзбекистон Республикаси қонун ҳужжатларига мувофиқ ушбу
          Шартнома шартларини бажармаганлик ёки лозим даражада бажармаганлик учун жавобгар
          бўладилар.
        </p>
        <p><b>6.2.</b> Фойдаланувчи ушбу Шартнома бўйича мажбуриятларни бажармаганлиги ёки
          лозим даражада бажармаганлиги натижасида унда юзага келган ҳар қандай зарар ёки бошқа
          салбий оқибатлар учун жавобгардир.
        </p>
        <p><b>6.3.</b> Ҳимояланмаган уланишлардан фойдаланган ёки зарарли дастур ва иловалар хавфи
          мавжуд бўлган қурилмага киритишда Фойдаланувчи ўзининг шахсга доир
          маълумотларининг хавфсизлиги учун тўлиқ жавобгар ҳисобланади.
        </p>
        <p><b>6.4.</b> Депозитарий Шартнома шартларини лозим даражада бажармаганлик учун, агар
          бундай бажармаганлик Фойдаланувчи томонидан Шартнома шартларини бажармаганлик
          оқибатида юзага келса, жавобгар ҳисобланмайди
        </p>
        <p><b>6.5.</b> Депозитарий Фойдаланувчи ушбу Оферта шартлари билан, шунингдек «UzCSD»
          Хизматида фаоллаштирилганда пуллик хизмат таклифлари билан танишмаганлиги ва (ёки)
          ўз вақтида танишмаганлиги сабабли Фойдаланувчида юзага келган ҳар қандай зарар учун
          жавобгар ҳисобланмайди.</p>
        <p><b>6.6.</b> Депозитарий Шартноманинг шартларини лозим даражада бажармаганлик учун
          агар бундай бажармаганлик Депозитарийнинг назорат доирасидан ташқарида бўлган
          ҳолатлар туфайли келиб чиқса, жавобгар ҳисобланмайди.</p>
        <p>Депозитарийнинг назорати доираси фақат «UzCSD» Хизматининг ишлаши билан
          чекланади ва учинчи шахсларнинг хатти-ҳаракатлари ёки давлат ёки суд органлари
          томонидан қўйилган таъқиқ ва чекловлар туфайли юзага келган носозликлар учун
          жавобгарликни ўз ичига олмайди.</p>
        <p><b>6.7.</b> «UzCSD» Хизматининг барча ахборот контенти, шу жумладан «UzCSD» Хизмати
          орқали очиқ бўлган ёки сўров орқали тақдим этилган маълумотлар, материаллар,
          функционал, дастурий таъминот ва контент "худди шундай", ўзгаришсиз, ҳеч қандай
          ўзгартириш ва қўшимчаларсиз тақдим этилади.
        </p>
        <p><b>6.8.</b> Депозитарий «UzCSD» Хизмати орқали мавжуд бўлган хизматлар, контент,
          материаллар, маълумотлар ва функсионаллик, ёки «UzCSD» хизмати орқали махфий
          маълумотларни узатиш билан боғлиқ ҳар қандай хавфсизлик қоидабузарликлари бўйича
          хизматдан ушбу шартномада кўзда тутилмаган усулларда фойдаланилганда ҳеч қандай
          тасдиқлаш ёки кафолатларни бермайди.</p>
        <p><b>6.9.</b> Фойдаланувчи ёки ҳар қандай учинчи томон олдида ҳар қандай шароитда
          етказилган зарар ёки йўқотишлар учун, шу жумладан,лекин улар билан чекланмаган ҳолда,
          даромад йўқотишидан кўрилган зарар, обрўсига зарар етказиш ёки Фойдаланувчи
          томонидан Депозитарий ҳамкорлари ва контрагентларидан UzCSD» хизмати орқали
          хизматларни сотиб олиш билан боғлиқ бошқа турдаги зарарлар, шунингдек, «UzCSD»
          Хизмати орқали сотиб олинган, қабул қилинган ёки тўланган ҳар қандай хизматлар,
          маълумотлар, контент учун Депозитарий жавобгар ҳисобланмайди.</p>
        <p><b>6.10.</b> Ҳар бир томон ушбу Шартномада белгиланган мажбурият чекловларини ва ушбу
          чекловлар томонлар ўртасидаги келишувлар учун зарур асос эканлигини тан олган ҳолда
          бошқа томон Шартнома тузишини тан олади, шу билан бирга ҳеч бир томон, аммо бундай
          ҳолатлар билан чекланмаган ҳолда, бундай томоннинг оқилона назоратидан ташқари
          бўлган ҳар қандай шароитлар, шу жумладан, ҳукумат фаолияти ёки террористик
          ҳаракатлари, зилзилалар, ёнғинлар, сув тошқинлари ёки бошқа табиий офатлар, мехнат
          шароитлари, электр узилишлари ва Интернетга уланиш узилишлари билан боғлиқ
          вазиятлар, туфайли юзага келган вазиятлар туфайли мажбуриятларини бажармаслик ёки
          кечикиш учун жавобгарликни ўз зиммасига олмайди.
        </p>
          <h1 class="text-center">
            <b>7. Шартномани бекор қилиш</b>
          </h1>
          <p><b>7.1.</b> Фойдаланувчи исталган вақтда ўзининг «UzCSD» Хизматидаги аккаунтини
            ўчириб, Шартномани бажаришдан бош тортишга ҳақли.
            </p>
          <p><b>7.2.</b> Шартнома аккаунт ўчирилган вақтлан бошлаб, бекор қилинган деб ҳисобланади.
            Ушбу Шартноманинг бекор қилинганлиги агар шундай хизматлар фаоллаштирилган бўлса,
            пуллик хизматлар бўйича тузилган шартномаларнинг бекор қилинганлигини англатмайди.</p>
          <p><b>7.3.</b> Фойдаланувчи Шартноманинг 9-бўлимида кўрсатилган манзилдаги электрон
            почтага хат юбориб, шахсга доир маълумотларга ишлов бериш тўғрисидаги ўз розилигини
            қайтариб олишга ҳақли.
          </p>
          <h1 class="text-center">
            <b>8. Келишмовчиликларни кўриб чиқиш тартиби</b>
          </h1>
          <p><b>8.1.</b> Ушбу Шартномадан келиб чиқадиган ҳар қандай низо музокаралар йўли билан ёки
            Фойдаланувчининг ёзма мурожаати асосида даъво киритиш тартибида кўриб чиқилиши
            керак. Мурожаатни кўриб чиқиш ва унинг мазмунига қўйиладиган талаблар Ўзбекистон
            Республикасининг амалдаги қонун ҳужжатлари билан белгиланади.
          </p>
          <p><b>8.2.</b> Агар юзага келган низо даъво киритиш тартибида ҳал этилмаса, Ўзбекистон
            Республикасининг амалдаги қонун ҳужжатларида белгиланган тартибга мувофиқ
            жавобгарнинг жойлашган ўрнидаги тааллуқлиги бўйича маҳкамавий судда кўриб чиқилади.</p>
          <p><b>8.3.</b>Фойдаланувчилар билан даъволар билан боғлиқ ишлар ва техник қўллаб-қувватлаш
            хизматлари Фойдаланувчиларга фақат сўровларни қабул қилиш, уларга ишлов бериш ва
            келиб тушган сўровларга жавобларни юбориш орқали амалга оширилади.
            Фойдаланувчиларнинг сўровлари ушбу Шартноманинг 9-бўлимида кўрсатилган ахборотга
            мувофиқ электрон почта ёки телефон рақами орқали қабул қилинади.</p>
          <h1 class="text-center">
            <b>9. Бошқа шартлар</b>
          </h1>
          <p><b>9.1.</b> Томонлар ушбу Шартномада бевосита назарда тутилмаган барча масалалар бўйича
            Ўзбекистон Республикасининг амалдаги қонун ҳужжатларига ва ишбилармонлик
            муомаласи анъаналарига амал қиладилар.</p>
          <p><b>9.2.</b>. Фойдаланувчи ушбу Шартноманинг барча шартларини сўзсиз қабул қилганлигини
            тасдиқлаган ҳолда, ушбу Офертани қабул қилади ва уни бажаришни ўз зиммасига олади.
          </p>
          <h1 class="text-center">
            <b>10. “Қимматли қоғозлар марказий депозитарийси” АЖ реквизитлари</b>
          </h1>
          <p>"Қимматли қоғозлар марказий депозитарийси" АЖ</p>
          <p>Манзил: Тошкент ш, Мирзо-Улуғбек тумани, Мустақиллик шоҳкўчаси, 107-уй</p>
          <p>СТИР: 203021987.</p>
          <p>ҚҚС: 326020005461.</p>
          <p>Ҳ/Р: 20208000804014949001.</p>
          <p>МФО: 01101.</p>
          <p>ОКЭД: 66190.</p>
          <p>Эл. почта манзили: info@uzcsd.uz.</p>
          <p>Тел: +99871 2110909 (ички рақам:172).</p>
        </div>
        <div v-show="this.$i18n.locale == 'en'" class="container mx-auto 2xl:px-24 xl:px-24 lg:px-24 md:px-24 sm:px-24 px-2 text-justify mt-5 indent-10" >
          <p>This document is an official offer (public offer) of the “Central Securities Depository” JSC
            (hereinafter referred to as the Depository) for capable individuals – holders of EMV bank cards
            (hereinafter referred to as the "User") to conclude an agreement on providing services in
            accordance with Article 367 and Part 2 of Article 369 of the Civil Code of the Republic of
            Uzbekistan on the use of the “UzCSD” Service (hereinafter - the “Agreement”). The User’s
            acceptance of the terms set forth in this Offer means his full agreement with these terms
            (acceptance of the Offer). From the moment of acceptance of the Offer, the Agreement is
            considered concluded. In the absence of explicit instructions in the Agreement, the parties are
            guided by the current legislation of the Republic of Uzbekistan and business practices.
          </p>
          <h1 class="text-center">
            <b>1. Terms and definitions</b>
          </h1>
          <p><b>UzCSD Service</b> - a software product that enables the User to open and manage his/her bank
            account opened with the Depository in online mode, make money transfers via bank card, as well
            as perform other actions available within the service functionality.
          </p>
          <p><b>User</b> – a capable individual, holder of an EMV bank card</p>
          <p><b>Bank Card</b> - a payment card functioning in online mode, issued by the bank of the Republic
            of Uzbekistan and registered in “UzCSD” Service.
          </p>
          <p><b>Personal Data</b> — information related to a certain individual or giving the possibility of his/her
            identification, provided by the User for the use of the “UzCSD” Service.
          </p>
          <p><b>Processing of personal data</b> - performance of one or more actions for collection,
            systematization, storage, modification, amendment, supplementation, use, provision, distribution,
            transfer, depersonalization and destruction of personal data.
          </p>
          <h1 class="text-center">
            <b>2. Acceptance procedure</b>
          </h1>
          <p><b>2.1.</b> Acceptance of this Offer is carried out by registration of the User in the “UzCSD” Service.
            The Service is available on iOS, Android devices and at uzcsd.uz website.
          </p>
          <p><b>2.2.</b> The User, by concluding this Agreement, gives perpetual consent to the processing of
            his/her personal data by the Depository in accordance with the requirements of the legislation of
            the Republic of Uzbekistan “On Personal Data”. 
          </p>
          <p>
            Including: payment credentials, mobile phone number, bank card details, full name,
            registration data, as well as other personal data necessary for the use of services available in the
            Service “UzCSD”. The User agrees to the collection, recording, systematization, storage,
            clarification, extraction, use, transfer, depersonalization, blocking, deletion and destruction of
            his/her personal data, carried out with or without the use of means of automation, for the purpose
            of execution of this Agreement. 
          </p>
          <p>In addition, the User consents to the transfer of his/her personal data to third parties for the
            purpose of execution of this Agreement.
          </p>
          <p><b>2.3.</b> The Depository guarantees the User to take all necessary measures to ensure
            confidentiality of his/her personal data in accordance with the legislation of the Republic of
            Uzbekistan and the Privacy Policy. The User's consent to the processing (including transfer to third
            parties) of his/her personal data is unconditional and indefinite. The User has the right to withdraw
            his/her consent to the processing of personal data at any time in accordance with Article 6.3 of this
            Agreement.
          </p>
          <p><b>2.4.</b> By accepting this Offer (concluding this Agreement), the User agrees to the proper
            verification and identification of his/her identity by the Depository while using the Services and
            services provided by third parties (organizations) through the “UzCSD” Service. Such verification
            and identification measures shall be carried out in accordance with the requirements established
            by the rules, instructions and other regulations of the indicated third parties (organizations).
          </p>
          <p><b>2.5.</b> Before using the “UzCSD” Service, it is necessary to completely familiarize with the
            terms and conditions of this Offer. In case of disagreement with any part or all conditions of the
            Offer, it is recommended to refrain from using the “UzCSD” Service.
          </p>
          <h1 class="text-center">
            <b>3. Subject of the agreement</b>
          </h1>
          <p><b>3.1.</b> The Depository provides Users with the opportunity to open securities accounts within
            the Depository and with partners (investment intermediaries) and manage their bank accounts
            opened with in the Depository using the “UzCSD” Service.  
          </p>
          <p>
            Users can also manage their bank cards, make transfers and other operations within the service
            functionality.

          </p>
          <p>
            Payment services for crediting funds to the User's bank card by the Depository shall be
            provided by a payment organization with which the Depository has concluded a relevant
            agreement.
          </p>
          <p><b>3.2.</b> The “UzCSD” Service may include services provided to Users on a paid basis. Activation
            of such services is carried out by the User independently at will after familiarization with the public
            offer, which contains the description of the service, terms of its provision, cost of use and other
            necessary information.
          </p>
          <p><b>3.3.</b> The Depository shall process the personal data provided by the User for the purpose of:</p>
          <p>
            <ul class="list-disc list-inside">
              <li>
                Proper fulfillment of the Depository’s obligations under the agreements or arrangements
                concluded with the User, as well as agreements with the Depository’s Partners, including
                for the purpose of identification or simplified identification of the User and enabling the
                User to use all UzCSD Services;
              </li>
              <li>
                Communicating with the User, including sending notifications, requests and information
                about the use of the Depository’s services or the provision of its services, as well as
                processing requests and inquiries from the User, including the subsequent transfer of
                requests and inquiries to the Depository’s counterparties or partners;
              </li>
              <li>
                Improvement of the quality and usability of the Depository's services, development of
                new services and provision of personalized services to the User;
              </li>
              <li>
                Conducting statistical and other research, based on anonymized data; 
              </li>
              <li>
                Distribution of publications from counterparties or partners of the Depository, including
                offers to participate in the purchase of financial instruments on the capital market
                conducted by partners or counterparties of the Depository, as well as distribution of
                advertising and information materials of counterparties or partners of the Depository
                related to financial instruments of the capital market through various means of
                communication and advertising targeting;
              </li>
              <li>
                Preventing illegal actions that contradict legislation or agreements using the "UzCSD"
                Service;
              </li>
              <li>
                Providing statistical data to Users based on capital market data.  
              </li>
            </ul>
          </p>
          <p><b>3.4.</b> Clicking on the appropriate buttons in the interface of the "UzCSD" Service (for
            example, "transfer", "pay" and others) is considered as a User's order, as well as consent to
            crediting and debiting the amount specified in the order in the corresponding window of the
            Service interface.
          </p>
          <p><b>3.5.</b> The "UzCSD" Service may contain services that can be provided to Users on a paid
            basis. Activation of such services is performed by the User independently, at will, after
            reviewing the public offer, which contains a description of the service, the terms of its provision,
            the cost of use and other information.
          </p>
          <h1 class="text-center">
            <b>4. Obligations of the parties</b>
          </h1>
          <p><b>4.1.</b> The Depository undertakes to:</p>
          <p>
            <ul class="list-disc list-inside">
              <li>To ensure uninterrupted operation of the “UzCSD” Service, except for cases when
                failures in the functioning of the Service occur due to the fault of third parties;
              </li>
              <li>
                Take legal, organizational and technical measures to protect personal data, ensuring their
                integrity and safety, as well as confidentiality and prevention of illegal processing of
                personal data;
              </li>
              <li>To inform the User in a timely manner about the news concerning the Depository and its
                Partners, who are providers of services available for payment through the UzCSD
                Service, as well as about changes and updates made to the Service;
              </li>
              <li>To send notifications to capital market regulatory authority or the Central Bank of the
                Republic of Uzbekistan, on any events that need to be informed to the owners of financial
                instruments or Users of the Service.
              </li>
            </ul>
          </p>
          <h1 class="text-center">
            <b>4.2. The Depository has the right to:</b>
          </h1>
          <p>
            <ul class="list-disc list-inside">
              <li>Receive information from the User regarding the identification and due diligence of the
                User and the operations performed by him/her in order to ensure compliance with the
                legislation of the Republic of Uzbekistan on countering the legalization of proceeds from
                criminal activities, financing of terrorism and financing the proliferation of weapons of
                mass destruction;
              </li>
              <li>Require the client to provide information about the sources of funds used during the
                transaction;
              </li>
              <li>Unilaterally make changes to this Offer by notifying the User of such changes via:
                <ul>
                  <li> - official website uzcsd.uz ;</li>
                  <li> - informational messages;</li>
                  <li> - Service interface;</li>
                  <li> - SMS alerts.</li>
                </ul>
              </li>
            </ul>
          </p>
          <h1 class="text-center">
            <b>4.3. The User undertakes to:</b>
          </h1>
          <p>
            <ul class="list-disc list-inside">
              <li>Abide by the terms and conditions of this Offer;</li>
              <li>Independently maintain the information security of the device, which is used to access the Service</li>
              <li>Using the “UzCSD” Service, to confirm and guarantee his/her legal capacity, as well as
                to refuse to use the “UzCSD” Service for the purposes of fraud or other illegal actions.
              </li>
            </ul>
          </p>
          <h1 class="text-center">
            <b>4.4. The User is not entitled to:</b>
          </h1>
          <p>
            <ul class="list-disc list-inside">
              <li>It is prohibited to transfer own rights and obligations under this Agreement to third
                parties without the prior written consent of the Depository. Any such attempted transfer
                shall be deemed invalid;
              </li>
              <li>The User undertakes all responsibility when sharing any information related to providing
                third parties with access to personal account in the “UzCSD” Service, including codes to
                confirm transactions, mobile phone number, as well as numbers and expiration date of
                bank cards.
              </li>
            </ul>
          </p>
          <h1 class="text-center">
            <b>5. The cost of services provided and the payment procedure</b>
          </h1>
          <p><b>5.1.</b> The cost of the services provided to the User is as follows:</p>
          <p>
            <ul class="list-disc list-inside">
              <li>
                Remote opening of a securities account ( with the use of KYC/AML, World-Check
                systems) with the Depository and the Depository's partners – <b>0.05% of the RCV.</b>
              </li>
              <li>
                Remote opening of one bank account in the Depository – <b>Free of charge;</b>
              </li>
              <li>
                Replenishment of the investment intermediary's cash account from the client – <b>0.7% of the transfer amount;</b>
              </li>
              <li>
                Transfer of funds from the cash account of an individual client to his/her bank card - <b>0.7% of the transfer amount;</b>
              </li>
              <li>
                Transfer of funds from the cash account within the investment intermediary to the bank card directly through “UzCSD” Service – <b>0.7% of the transfer amount;</b>
              </li>
              <li>
                Receipt of publications of the Depository's partners related to capital market financial instruments – <b>Free of charge;</b>
              </li>
            </ul>
        </p>
        <p><b>5.2.</b> According to the terms and conditions of this Offer, the User undertakes to pay the
          Depository a cash amount for the work performed, depending on the service provided, in
          accordance with the tariffs set forth in clause 5.1 hereof.  
        </p>
        <p>
          The service of transferring funds from a cash account held with an investment intermediary
          to a bank card shall be performed by a payment organization with which the Depository has a
          relevant agreement.
        </p>
        <p><b>5.3.</b> The amount for the service provided by the “UzCSD” service will be withheld by the
          Depository at the time of the transaction.
        </p>
        <h1 class="text-center">
            <b>6. Liability of the parties</b>
        </h1>
        <p><b>6.1.</b> The Parties shall be liable for non-fulfillment or improper fulfillment of the terms of this
          Agreement in accordance with the legislation of the Republic of Uzbekistan.
        </p>
        <p><b>6.2.</b> The User shall be liable for any losses or other negative consequences incurred by the
          User as a result of non-fulfillment or improper fulfillment of obligations under this Agreement.
        </p>
        <p><b>6.3.</b> The User is solely responsible for the security of his/her personal data if it is entered on a
          device using insecure connections or if there is a threat of malicious programs and applications.
        </p>
        <p><b>6.4.</b> The Depository shall not be liable for improper fulfillment of the terms and conditions of
          the Agreement if such non-fulfillment was caused by the User's failure to fulfill the terms and
          conditions of the Agreement.
        </p>
        <p><b>6.5.</b> The Depositary shall not be liable for any losses incurred by the User due to the fact that
          the User did not become familiar with and (or) did not timely become familiar with the terms and
          conditions of this Offer, as well as with the offers of the fee-based services when activating them
          in the “UzCSD” Service.</p>
        <p><b>6.6.</b> The Depository shall not be liable for any failure to properly perform the terms of this
          Agreement if such failure is caused by circumstances beyond the Depository's control. The
          Depository's area of control shall be limited only to the operability of the UzCSD Service and shall
          not include liability for failures caused by the actions of third parties or prohibitions and
          restrictions imposed by governmental authorities or courts</p>
        <p><b>6.7.</b> All information content of the “UzCSD” Service, including content, software,
          functionality, materials and information provided in connection with the request or available
          through the “UzCSD” Service, is provided “as is”, in unchanged form, without any changes and
          additions.
          </p>
        <p><b>6.8.</b> The Depository does not represent or warrant the services, content, materials, information
          and functionality available through the UzCSD Service or any breach of security related to the
          transmission of confidential information through the UzCSD Service when the service is used in
          ways not specified in this Agreement.
        </p>
        <p><b>6.9.</b> The Depository shall not be liable to the User or any third party for any damages or losses
          arising under any circumstances, including, but not limited to, loss of profits, damage to reputation
          or other types of losses related to the User's purchase of services from counterparties or partners
          of the Depository through the UzCSD Service, as well as for any services, information, content
          purchased, received or paid for through the UzCSD Service.</p>
        <p><b>6.10.</b> Each Party acknowledges that the other Party concludes this Agreement acknowledging
          the limitations of liability set forth in this Agreement and that these limitations are a necessary
          basis for the arrangements between the Parties, and neither Party shall be liable for default or delay
          caused by any conditions beyond such Party's reasonable control, including such circumstances as
          governmental activities or acts of terrorism, earthquakes, fires, floods, or other disasters, related
          to working conditions, power failures and Internet connection disruptions, but not limited to these
          circumstances </p>
          <h1 class="text-center">
            <b>7. Termination of the contract</b>
          </h1>
          <p><b>7.1.</b> The User shall have the right to refuse to fulfill the Agreement at any time by deleting
            his/her account in the “UzCSD” Service.</p>
          <p><b>7.2.</b> . The Agreement shall be deemed terminated immediately upon deletion of the account.
            Termination of this Agreement does not mean termination of Agreements for paid Services, if such
            Services have been activated.</p>
          <p><b>7.3.</b> The User has the right to revoke his/her consent to the processing of personal data by
            sending an e-mail to the address specified in Section 9 of the Agreement.
          </p>
          <h1 class="text-center">
            <b>8. Procedure for consideration of disagreements</b>
          </h1>
          <p><b>8.1.</b> Any dispute arising from this Agreement shall be resolved through peaceful means or
            through the claim procedure on the basis of a written application of the User. Terms of
            consideration of the appeal and requirements to its content shall be determined by the current
            legislation of the Republic of Uzbekistan.</p>
          <p><b>8.2.</b> If the dispute that has arisen is not settled in a claim-based manner, it will be considered
            in a subordinate court at the location of the defendant in accordance with the current legislation
            of the Republic of Uzbekistan..</p>
          <p><b>8.3.</b>Work User's complaints and technical support services are provided to Users
            exclusively by receiving requests, processing them and sending responses to the received
            requests. The Users' requests are accepted by e-mail or by phone according to the information
            specified in Section 9 of the Agreement.
          </p>
          <h1 class="text-center">
            <b>9. Other conditions</b>
          </h1>
          <p><b>9.1.</b> In all cases not directly regulated by this Agreement, the parties shall be guided by the
            current legislation of the Republic of Uzbekistan and business practices.</p>
          <p><b>9.2.</b> By confirming his/her unconditional consent to all the terms and conditions of this
            Agreement, the User accepts this Offer and undertakes to fulfill it.
          </p>
          <h1 class="text-center">
            <b>10. Details of Central Securities Depository "Central Securities Depository" JSC</b>
          </h1>
          <p>“Central Securities Depository” JSC</p>
          <p>Address: 107, Mustakillik avenue, Mirzo-Ulugbek district, Tashkent</p>
          <p>TIN: 203021987.</p>
          <p>VAT: 326020005461.</p>
          <p>Current account: 20208000804014949001.</p>
          <p>MFO: 01101.</p>
          <p>OKED: 66190.</p>
          <p>E-mail: info@uzcsd.uz.</p>
          <p>Tel: +99871 2110909 (ext: 172).</p>
        </div>
      </div>
    </div>
  </template>

<script>
import LoadingState from '@/components/LoadingState.vue';
import axios from 'axios'
export default {
 components:{
   LoadingState
 },
 data: () => {
   return {
     loading:true
   };
 },
 methods: {
  async getPDFPath() {
     this.pdfsrc = null;
     let uzId = 2209;
     let ruId = 2207;
     let enId = 2206;

    // let uzId = 2209;
    // let ruId = 4;
    // let enId = 2206;
     this.loading = true;

     try {
       let locale = this.$i18n.locale;
       let response;

       if (locale === 'ru') {
         response = await axios.get('/api/Website/GetOfferta/' + ruId);
       } else if (locale === 'uz') {
         response = await axios.get('/api/Website/GetOfferta/' + uzId);
       } else if (locale === 'en') {
         response = await axios.get('/api/Website/GetOfferta/' + enId);
       }

       if (response && response.data) {
         let pdfString = response.data;
         this.pdfsrc = "data:application/pdf;base64," + pdfString;
       }
     } catch (error) {
       console.error(error);
     } finally {
       this.loading = false; 
     }
   },
   async Download(locale) {
    let uzId = 2209;
    let ruId = 2207;
    let enId = 2206;
    let path = null;
    try {
          switch(locale){
            case 'ru':
            path = "/api/Website/GetDownloadPdfById/" + ruId;
            break;
            case 'uz':
            path = "/api/Website/GetDownloadPdfById/" + uzId;
            break;
            case 'en':
            path = "/api/Website/GetDownloadPdfById/" + enId;
            break;
          }
        
        let response = await this.axios.get(path, {
            responseType: 'blob' // Set responseType to 'blob' to receive binary data
        });

        let blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a temporary URL for the blob
        let url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        let link = document.createElement('a');
        link.href = url;

        // Simulate a click on the link to trigger the download
        link.click();

        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.log(error);
    } finally {
        this.loading = false;
    }
  },
 
},
 mounted() {
  this.getPDFPath();
   this.visible = true;
 }
};
</script>

