<template>
<div class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900">
    <div class="container mx-auto">
      <div class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center">
        <h1 class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500">{{ $t('navbar.vacancy') }}</h1>
      </div>
      <a onclick="window.history.back()" class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
        </svg>
        <span>{{ $t('table.go-back') }}</span>
      </a>
      <nav aria-label="Breadcrumb" class="2xl:block xl:block lg:block md:block hidden">
        <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
          <li class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900">
            <a @click="this.$router.push('/')" class="inline-flex items-center font-medium">
              <svg class="w-4 h-4 mr-2" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              <span class="hidden sm:block">{{ $t('ceo-message.main-screen') }}</span>
            </a>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg class="w-6 h-6 text-gray-400" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"></path>
              </svg>
              <span class="ml-1 font-medium md:ml-2 select-none">{{ $t('navbar.vacancy') }}</span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="container mx-auto border dark:border-lime-600 m-5 rounded-xl shadow bg-slate-50/25 dark:bg-gray-777">
    
    <div class="dark:bg-gray-900">
      <div class="container mx-auto pt-5 pb-1 2xl:px-20 xl:px-10 lg:px-10 md:px-10 sm:px-5 px-5">
        <div id="alert-additional-content-1"
             class="p-4  rounded-lg bg-gray-100 dark:bg-gray-777 dark:border-lime-500"
             role="alert">
             <div class="lg:flex justify-between sm:flex-wrap">
                <div class="flex-initial lg:w-3/4 sm:w-full">
                    <h2 v-show="this.$i18n.locale == 'ru'" class="font-bold text-gray-700 dark:text-lime-400 text-xl">{{ this.newVacancy.titleRu}}</h2>
                    <h2 v-show="this.$i18n.locale == 'uz'" class="font-bold text-gray-700 dark:text-lime-400 text-xl">{{ this.newVacancy.titleUz }}</h2>
                    <h2 v-show="this.$i18n.locale == 'en'" class="font-bold text-gray-700 dark:text-lime-400 text-xl">{{ this.newVacancy.titleEn }}</h2>
                </div>
                <div class="flex-nowrap lg:w-1/4 sm:w-full">
                    <h2 class="font-medium text-blue-500 dark:text-lime-400 items-end">{{ $t('vacancy.aplicationDeadline') }}: {{ formatedDate(this.newVacancy.expireDate) }}</h2>
                    <h2 class="font-medium text-blue-500 dark:text-lime-400 items-end">{{ $t('vacancy.aplication-testing') }}: {{ formatedDate(this.newVacancy.dateOfTesting) }}</h2>
                </div>
              </div>
          <div class="mt-2 text-bas">          
            <div class="flex">
            <div class="p-6 pb-0 space-y-6 text-gray-500 dark:text-lime-400">
              <div v-show="this.$i18n.locale == 'ru'" v-html="this.newVacancy.textRu"></div>
              <div v-show="this.$i18n.locale == 'uz'" v-html="this.newVacancy.textUz"></div>
              <div v-show="this.$i18n.locale == 'en'" v-html="this.newVacancy.textEn"></div>
              <p class="2xl:text-base xl:text-base lg:text-base md:text-base sm:text-xs text-xs leading-relaxed text-gray-500 dark:text-lime-400">
              <b>{{ $t('vacancy.work-schedule') }}:</b>
              <pre class="list-disc dark:text-lime-400">{{ $t('vacancy.day') }}:  9:00-18:00</pre>
            </p>
            </div>
            </div>
          </div>
        </div>
     
        <div v-if="visible" class="container mx-auto dark:border-lime-600 m-5 lg:pb-12 sm:pb-0 dark:bg-gray-777">
                <p class="2xl:text-base xl:text-base lg:text-base md:text-base sm:text-xs font-semibold selection: leading-relaxed text-gray-500 dark:text-lime-400 mt-1">{{ $t('vacancy.template-resume') }}:</p>
                <button class="w-1/6" v-show="this.$i18n.locale == 'ru'"  @click=Download(this.$i18n.locale)><img src="../assets/img/doc.png" width="50" alt="pdf" height="50"></button>
                <button class="w-1/6" v-show="this.$i18n.locale == 'uz'"  @click=Download(this.$i18n.locale)><img src="../assets/img/doc.png" width="50" alt="pdf" height="50"></button>
                <button class="w-1/6" v-show="this.$i18n.locale == 'en'"  @click=Download(this.$i18n.locale)><img src="../assets/img/doc.png" width="50" alt="pdf" height="50"></button>
                <p class="2xl:text-base xl:text-base lg:text-base md:text-base sm:text-xs font-semibold selection: leading-relaxed text-gray-500 dark:text-lime-400 mt-1">{{ $t('vacancy.CV') }}:</p>
                <input
                class="pdfRu rounded-md w-full text-sm text-gray-900 border cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                type="file"
                ref="file"
                @change="uploadFile()"
                :class="{
                  'border-green-500':
                  this.applicationForVacancy.applicationForVacancyFileId !== null,
                }"
              />
              <div class = "flex mt-2">
                <div class="flex-auto lg:w-1/1 pr-1">
                  <p class="2xl:text-base xl:text-base lg:text-base md:text-base sm:text-xs font-semibold leading-relaxed text-gray-500 dark:text-lime-400 mt-1">{{ $t('vacancy.fullname') }}:</p>
                  <input class="text-xm p-2 w-full dark:bg-gray-900" type="text" v-model="this.applicationForVacancy.fullName" maxlength="100"/>
                  <p v-if="!isFullNameValid" class="text-xs text-red-500 mt-1">*{{ $t('vacancy.validation-message-fullName')}}</p>
                </div>
              </div>
            <div class="lg:flex sm:flex-wrap"> 
              <div class="lg:flex-auto sm:flex-wrap lg:w-1/2 md:text-base sm:w-10/12 pr-1">
                <p class="2xl:text-base xl:text-base lg:text-base md:text-xs sm:text-xs font-semibold leading-relaxed text-gray-500 dark:text-lime-400 mt-1">{{ $t('vacancy.pinfl') }}:</p>
                <input class="text-xm p-2 w-full dark:bg-gray-900" type="text" v-model="this.applicationForVacancy.pINFL" maxlength="14">
                <p v-if="!isPINFLvalid" class="text-xs text-red-500 mt-1">*{{ $t('vacancy.validation-message-pinfl') }}</p>
              </div>  
                <div class="lg:flex-auto sm:flex-wrap justify-normal  lg:w-1/2 md:text-base sm:w-10/12 pr-1">
                  <p class="2xl:text-base xl:text-base lg:text-base md:text-base sm:text-xs font-semibold leading-relaxed text-gray-500 dark:text-lime-400 mt-1">{{ $t('vacancy.email') }}:</p>
                  <input class="text-xm p-2 w-full dark:bg-gray-900" type="text" id="email" v-model="this.applicationForVacancy.email" placeholder="example@mail.ru" maxlength="100"/>
                  <p v-if="!isEmailValid" class="text-xs text-red-500 mt-1">*{{ $t('vacancy.validation-message-email') }}</p>
                </div>
             </div>
            <div class="lg:float-right sm:justify-center pt-3.5 grid">
              <button class="lg:text-base md:text-base sm:text-xs transition-colors ease-in p-1 rounded-lg font-semibold hover:bg-blue-200 hover:w-full hover:text-blue-500 dark:hover:text-lime-400 dark:text-lime-300 cursor-pointer dark:hover:bg-gray-700 text-gray-500" 
              @click="updateOrAdd()" type="submit">{{ $t('vacancy.send-application') }}</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  data(){
        return {
          newVacancy:{
          dateOfTesting:null,
          departmenId:null,
          expireDate:null,
          status:null,
          textEn:null,
          textRu:null,
          textUz:null,
          titleEn:null,
          titleRu:null,
          titleUz:null,
          id:null,
          departmentNameRu: null,
          departmentNameUz: null,
          departmentNameEn: null,
         },
         visible: false,
         applicationForVacancy:{
          vacancyId:null,
          pINFL:null,
          email:'',
          fullName:null,
          date: new Date().toISOString().substr(0, 10),
          applicationForVacancyFileId:null,
          status:null,
          id: 0,
          result: null
          },
    };
  },

  methods: {
    validateFullName() {
      this.isFullNameValid = this.applicationForVacancy.fullName.length > 8;
    },
    validatePINFL() {
      this.isPINFLvalid = this.applicationForVacancy.pINFL.length > 13;
    },
    validateEmail() {
      this.isEmailValid = this.applicationForVacancy.email.length > 8 && this.applicationForVacancy.email.includes("@" && ".");
      },
    async updateOrAdd() {
      this.loading=true;
      this.validateFullName();
      this.validatePINFL();
      this.validateEmail();
      if(this.isEmailValid && this.isPINFLvalid && this.isFullNameValid){
        try {
          this.applicationForVacancy.status = "ACTIVE";
          this.applicationForVacancy.vacancyId = this.newVacancy.id;
  
          const response = await this.axios.post(
            "/api/Website/ApplicationForVacancy/SendApplication",
            this.applicationForVacancy,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
            
          );
          this.loading=false;
          if (response.status === 200) {
            const successMessage = this.$t('vacancy.application-success-submit');
             toast.success((successMessage), {
              position: "top-center",
              transition: "flip",
              newestOnTop: true,
              toastStyle: {
                fontSize: "14px",
              },
              autoClose: 1000, 
              onClose: () => {
              this.clearForm();
              this.$router.push("/vacancy");}
            });
          }
        } 
        catch (error) {
          this.loading=false;
          const errorMessage = this.$t('vacancy.application-error-submit');
          toast.error((errorMessage), {
            position: "top-center",
            transition: "flip",
            newestOnTop: true,
            toastStyle: {
              fontSize: "14px",
            },
          });
        }
      }
          
  },
  async Download(locale) {
    let uzId = 2164;
    let ruId = 2164;
    let enId = 2164;
    let path = null;
    try {
          switch(locale){
            case 'ru':
            path = "/api/Website/GetResumeShablonPdfById/" + ruId;
            break;
            case 'uz':
            path = "/api/Website/GetResumeShablonPdfById/" + uzId;
            break;
            case 'en':
            path = "/api/Website/GetResumeShablonPdfById/" + enId;
            break;
          }

        let response = await this.axios.get(path, {
            responseType: 'blob' // Set responseType to 'blob' to receive binary data
        });

        let blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a temporary URL for the blob
        let url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        let link = document.createElement('a');
        link.href = url;

        // Simulate a click on the link to trigger the download
        link.click();

        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.log(error);
    } finally {
        this.loading = false;
    }
  },
  async uploadFile() {
      const file = this.$refs['file'].files[0];
      const formData = new FormData();
      formData.append("file", file);

      const response = await this.axios.post(
        "api/Website/ApplicationForVacancy/UploadResume",
        formData
      );
      
      this.applicationForVacancy.applicationForVacancyFileId = JSON.stringify(
          response.data
        );
    },
    clearForm(){
      this.applicationForVacancy = {
          vacancyId:null,
          pINFL:null,
          email:null,
          fullName:null,
          date:new Date().toISOString().substr(0, 10),
          applicationForVacancyFileId:null,
          status:null,
          id: 0,
          result: null
      }
    },
    formatedDate(item) {
      const dateTime = new Date(item);
      return dateTime.toLocaleDateString();
    },
  },
  mounted() {
          let today = new Date();
          if (sessionStorage.getItem("VACANCY")) {
              this.newVacancy = JSON.parse(sessionStorage.getItem("VACANCY"));
              let expire = new Date(this.newVacancy.expireDate);
              if(expire >= today){
                this.visible = true;
                console.log(today);
              }
            }
          },
    watch: {
    'applicationForVacancy.email'() {
      this.validateEmail();
    },
    'applicationForVacancy.pINFL'() {
      this.validatePINFL();
    },
    'applicationForVacancy.fullName'() {
      this.validateFullName();
    },
  },
};


</script>