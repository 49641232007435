<template>
  <div v-if="loading">
    <LoadingState></LoadingState>
  </div>
  <div v-else>
    <div
      class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900"
    >
      <div class="container mx-auto">
        <div
          class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center"
        >
          <h1
            class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500"
          >
            {{ $t("navbar.dictionary-terms") }}
          </h1>
        </div>

        <a
          onclick="window.history.back()"
          class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            />
          </svg>
          <span>{{ $t("table.go-back") }}</span>
        </a>

        <nav
          aria-label="Breadcrumb"
          class="2xl:block xl:block lg:block md:block hidden"
        >
          <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
            <li
              class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900"
            >
              <a
                @click="this.$router.push('/')"
                class="inline-flex items-center font-medium"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="white"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                <span class="hidden sm:block">{{
                  $t("ceo-message.main-screen")
                }}</span>
              </a>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="white"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="ml-1 font-medium md:ml-2 select-none">{{
                  $t("navbar.dictionary-terms")
                }}</span>
              </div>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div
      class="container mx-auto border dark:border-lime-600 m-5 rounded-xl shadow py-5 bg-slate-50/25 dark:bg-gray-777"
    >
      <div class="flex flex-col items-center px-20 dark:bg-gray-900 dark:py-5">
        <div class="flex flex-col container mx-auto justify-center space-y-2">
          <div
            class="flex justify-between basis-3/4 items-center overflow-hidden p-2"
          >
            <h1 class="text-lg font-bold dark:text-white">
              {{ $t("navbar.dictionary-terms") }}
            </h1>

            <div class="relative basis-2/5">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                v-model="searchQuery"
                class="block w-full p-[10px] pl-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-777 dark:text-lime-400 dark:focus:ring-lime-400 dark:border-lime-400"
              />
            </div>
          </div>
          <div class="basis-3/4 items-center">
            <div class="accordion-content p-1 text-gray-700 my-1">
              <div class="flex flex-col w-full">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div
                    class="align-middle inline-block min-w-full sm:px-6 lg:px-8"
                  >
                    <div
                      class="border overflow-hidden border-b border-gray-200 sm:rounded-lg"
                    >
                      <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              class="px-3 py-2 text-center text-xs font-medium uppercase tracking-wider"
                            >
                              {{ $t("others.word") }}
                            </th>
                            <th
                              scope="col"
                              class="px-3 py-2 text-center text-xs font-medium uppercase tracking-wider w-4/5"
                            >
                              {{ $t("others.description") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody class="dark:bg-gray-777">
                          <tr
                            v-show="this.$i18n.locale == 'ru'"
                            v-for="(item, index) in paginatedData"
                            :key="index"
                            class="h-4"
                          >
                            <td
                              class="px-3 py-2 whitespace-nowrap text-center text-sm text-gray-600 border-r border-b font-semibold"
                            >
                              <p
                                class="text-base text-blue-500 whitespace-normal dark:text-lime-500"
                              >
                                {{ item.keyWordRu }}
                              </p>
                            </td>
                            <td
                              class="px-3 py-2 whitespace-nowrap text-justify text-sm text-gray-600 border-l border-b"
                            >
                              <p
                                class="text-base whitespace-normal dark:text-lime-500"
                              >
                                {{ item.descriptionRu }}
                              </p>
                            </td>
                          </tr>
                          <tr
                            v-show="this.$i18n.locale == 'uz'"
                            v-for="(item, index) in paginatedData"
                            :key="index"
                            class="h-4"
                          >
                            <td
                              class="whitespace-nowrap text-sm text-gray-600 dark:text-white font-mono text-center"
                            >
                              {{ index + 1 }}
                            </td>
                            <td
                              class="px-3 py-2 whitespace-nowrap text-center text-sm text-gray-600 border-x"
                            >
                              <p
                                class="text-base whitespace-normal dark:text-lime-500"
                              >
                                {{ item.keyWordUz }}
                              </p>
                            </td>
                            <td
                              class="px-3 py-2 whitespace-nowrap text-justify text-sm text-gray-600 border-l"
                            >
                              <p
                                class="text-base whitespace-normal dark:text-lime-500"
                              >
                                {{ item.descriptionUz }}
                              </p>
                            </td>
                          </tr>
                          <tr
                            v-show="this.$i18n.locale == 'en'"
                            v-for="(item, index) in paginatedData"
                            :key="index"
                            class="h-4"
                          >
                            <td
                              class="whitespace-nowrap text-sm text-gray-600 dark:text-white font-mono text-center"
                            >
                              {{ index + 1 }}
                            </td>
                            <td
                              class="px-3 py-2 whitespace-nowrap text-center text-sm text-gray-600 border-x"
                            >
                              <p
                                class="text-base whitespace-normal dark:text-lime-500"
                              >
                                {{ item.keyWordEn }}
                              </p>
                            </td>
                            <td
                              class="px-3 py-2 whitespace-nowrap text-justify text-sm text-gray-600 border-l"
                            >
                              <p
                                class="text-base whitespace-normal dark:text-lime-500"
                              >
                                {{ item.descriptionEn }}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="flex flex-col items-end mt-1">
                      <div class="flex text-gray-700 space-x-1">
                        <button
                          @click="previousPage"
                          :disabled="currentPage === 1"
                          class="h-7 w-7 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 dark:hover:border-lime-500 dark:hover:text-lime-500 transition-all ease-linear cursor-pointer rounded-md"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="4"
                            height="4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-chevron-left w-6 h-6"
                          >
                            <polyline points="15 18 9 12 15 6"></polyline>
                          </svg>
                        </button>
                        <span
                          v-for="pageNumber in pageNumbers"
                          :key="pageNumber"
                          class="flex h-7 font-medium bg-white border transition-all ease-linear rounded-md"
                        >
                          <button
                            @click="goToPage(pageNumber)"
                            :disabled="currentPage === pageNumber"
                            :class="{ active: currentPage === pageNumber }"
                            class="w-7 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-md"
                          >
                            {{ pageNumber }}
                          </button>
                        </span>

                        <button
                          @click="nextPage"
                          :disabled="currentPage === totalPages"
                          class="h-7 w-7 ml-1 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 dark:hover:border-lime-500 dark:hover:text-lime-500 transition-all ease-linear cursor-pointer rounded-md"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="4"
                            height="4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-chevron-right w-6 h-6"
                          >
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingState from "@/components/LoadingState";
import axios from "axios";

export default {
  components: {
    LoadingState,
  },
  data() {
    return {
      searchQuery: "",
      glossaryData: [],
      activeIndex: null,
      currentPage: 1,
      itemsPerPage: 5,
      loading: true,
    };
  },
  methods: {
    loadTable() {
      axios
        .get(`/api/Website/GetGlossaries`)
        .then((response) => {
          this.glossaryData = response.data;
          console.log(this.glossaryData);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
  },
  computed: {
    filterData() {
      let filteredItems = this.glossaryData;
      switch (this.$i18n.locale) {
        case "ru":
          return filteredItems.filter((item) => {
            return item.keyWordRu
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase());
          });

        case "en":
          return filteredItems.filter((item) => {
            return item.keyWordEn
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase());
          });

        case "uz":
          return filteredItems.filter((item) => {
            return item.keyWordUz
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase());
          });

        default:
          return filteredItems;
      }
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filterData.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filterData.length / this.itemsPerPage);
    },
    pageNumbers() {
      const pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  mounted() {
    this.loadTable();
  },
};
</script>
