<template>
  <div v-if="loading">
    <LoadingState></LoadingState>
  </div>
  <div v-else>
    <div
      class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900"
    >
      <div class="container mx-auto">
        <div
          class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center"
        >
          <h1
            class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500"
          >
            {{ $t("navbar.estimate-execution") }}
          </h1>
        </div>

        <a
          onclick="window.history.back()"
          class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            />
          </svg>
          <span>{{ $t("table.go-back") }}</span>
        </a>

        <nav
          aria-label="Breadcrumb"
          class="2xl:block xl:block lg:block md:block hidden"
        >
          <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
            <li
              class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900"
            >
              <a
                @click="this.$router.push('/')"
                class="inline-flex items-center font-medium"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="white"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                <span class="hidden sm:block">{{
                  $t("ceo-message.main-screen")
                }}</span>
              </a>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="white"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="ml-1 font-medium md:ml-2 select-none">{{
                  $t("navbar.estimate-execution")
                }}</span>
              </div>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div
      class="container mx-auto border dark:border-lime-600 m-5 rounded-xl shadow py-5 bg-slate-50/25 dark:bg-gray-777"
    >
      <div class="dark:py-5 2xl:px-48 xl:px-24 lg:px-10 px-5 dark:bg-gray-900">
        <div class="flex flex-col w-full container mx-auto">
          <div class="accordion w-full space-y-1">
            <div v-for="(item, index) in years" :key="index">
              <button
                @click="
                  activeIndex === index
                    ? (activeIndex = null)
                    : (activeIndex = index),
                    showYear(item)
                "
                class="bg-gradient-to-br from-white to-slate-200 dark:bg-gradient-to-br dark:from-gray-777 dark:to-gray-900 dark:hover:border-lime-500 dark:hover:text-lime-500 dark:border-lime-900 dark:text-white dark:focus:border-lime-500 dark:focus:text-lime-500 accordion-header transition-colors ease-linear flex items-center justify-between w-full px-4 py-1.5 text-left font text-gray-900 rounded-lg hover:border-blue-500 hover:text-blue-500 focus:text-blue-500 focus:outline-none focus:bg-blue-200 focus:border-blue-500 border"
              >
                <span>{{ item }}</span>

                <svg
                  :class="{
                    'rotate-180': activeIndex === index,
                    'rotate-0': activeIndex !== index,
                  }"
                  class="w-5 h-5 transform transition-transform duration-300"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
              <div
                v-show="activeIndex === index"
                class="accordion-content p-1 text-gray-700 bg-white dark:bg-gray-777 dark:border-lime-500 border my-1 border-blue-500 rounded-lg"
              >
                <div class="flex flex-col w-full">
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                      class="align-middle inline-block min-w-full sm:px-6 lg:px-8"
                    >
                      <div
                        class="border overflow-hidden border-b border-gray-200 sm:rounded-lg"
                      >
                        <table class="min-w-full divide-y divide-gray-200">
                          <thead class="bg-gray-100">
                            <tr>
                              <th
                                scope="col"
                                class="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                №
                              </th>
                              <th
                                scope="col"
                                class="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {{ $t("table.header") }}
                              </th>
                              <th
                                scope="col"
                                class="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
                              >
                                {{ $t("table.file") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            v-for="(fliterArray, filterIndex) in paginatedData"
                            :key="filterIndex"
                          >
                            <tr
                              v-show="infoArray.language == this.$i18n.locale"
                              v-for="(
                                infoArray, arrayIndex
                              ) in fliterArray.informations"
                              :key="arrayIndex"
                              class="h-4"
                            >
                              <td
                                class="whitespace-nowrap text-sm text-gray-500 dark:text-white font-mono text-center w-12"
                              >
                                {{ filterIndex + 1 }}
                              </td>
                              <td
                                class="px-3 py-2 whitespace-nowrap text-sm text-gray-500 border-x"
                              >
                                <p
                                  class="text-base whitespace-normal text-blue-500 dark:text-lime-500"
                                >
                                  {{ infoArray.header }}
                                </p>
                              </td>
                              <td
                                class="text-center whitespace-nowrap text-sm text-gray-500 flex justify-center items-center py-2"
                              >
                                <a
                                  target="_blank"
                                  :href="
                                    '/api/Website/GetPdfById' +
                                    infoArray.informationFileId
                                  "
                                >
                                  <img
                                    src="../assets/img/pdf.png"
                                    alt="pdf"
                                    width="35"
                                    height="35"
                                  />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                          <tr v-show="filteredDataYear.length == 0">
                            <td
                              colspan="2"
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-lime-500"
                            >
                              {{ $t("table.doesnt-exist") }}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div class="flex flex-col items-end mt-1">
                        <div class="flex text-gray-700 space-x-1">
                          <button
                            @click="previousPage"
                            :disabled="currentPage === 1"
                            class="h-7 w-7 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 dark:hover:border-lime-500 dark:hover:text-lime-500 transition-all ease-linear cursor-pointer rounded-md"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="4"
                              height="4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-chevron-left w-6 h-6"
                            >
                              <polyline points="15 18 9 12 15 6"></polyline>
                            </svg>
                          </button>
                          <span
                            v-for="pageNumber in pageNumbers"
                            :key="pageNumber"
                            class="flex h-7 font-medium bg-white border transition-all ease-linear rounded-md"
                          >
                            <button
                              @click="goToPage(pageNumber)"
                              :disabled="currentPage === pageNumber"
                              :class="{ active: currentPage === pageNumber }"
                              class="w-7 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-md"
                            >
                              {{ pageNumber }}
                            </button>
                          </span>

                          <button
                            @click="nextPage"
                            :disabled="currentPage === totalPages"
                            class="h-7 w-7 ml-1 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 dark:hover:border-lime-500 dark:hover:text-lime-500 transition-all ease-linear cursor-pointer rounded-md"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="4"
                              height="4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-chevron-right w-6 h-6"
                            >
                              <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingState from "@/components/LoadingState";
import axios from "axios";
export default {
  components: {
    LoadingState,
  },
  data() {
    return {
      years: [],
      accordionItems: [
        {
          year: null,
          date: null,
          informations: [
            {
              id: null,
              header: null,
              language: null,
            },
          ],
        },
      ],
      valueYear: null,
      activeIndex: null,
      currentPage: 1,
      itemsPerPage: 5,
      loading: true,
    };
  },
  methods: {
    showYear(item) {
      this.valueYear = item;
    },
    loadTable() {
      axios
        .get(`/api/Website/Information/GetExecutionOfTheBusinessPlans`)
        .then((response) => {
          this.accordionItems = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
  },
  computed: {
    filteredDataYear() {
      return this.accordionItems.filter((item) => item.year === this.valueYear);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredDataYear.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredDataYear.length / this.itemsPerPage);
    },
    pageNumbers() {
      const pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  mounted() {
    this.loadTable();
    const currentYear = new Date().getFullYear();
    const startYear = 2018;
    this.years = Array.from(
      { length: currentYear - startYear + 1 },
      (_, i) => startYear + i
    ).reverse();
  },
};
</script>

<style>
.dark .active {
  background: #a3e635;
  color: white;
}
.active {
  background: #3f83f8;
  color: white;
}
.btn {
  margin-left: auto;
}
</style>
