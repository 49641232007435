<template>
  <div v-if="loading">
    <LoadingState></LoadingState>
  </div>
  <div v-else class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900">
    <div class="container mx-auto">
      <!-- ... Breadcrumb code ... -->
    </div>
  </div>
  <div class="container mx-auto border dark:border-lime-600 m-5 bg-slate-50/25 dark:bg-gray-777">
    <div class="dark:bg-gray-900">
      <div class="container mx-auto flex justify-center dark:text-lime-400">
        <p class="font-bold text-xl pb-5 dark:mt-4 pt-5 dark:text-white">{{ $t('vacancy.current-vacancies') }}</p>
      </div>
    </div>

    <div class="dark:bg-gray-900 pb-5">
      <div v-for="(vacancy, index) in vacancies" :key="index">
        <div class="container mx-auto 2xl:px-20 xl:px-10 lg:px-10 md:px-10 sm:px-5 px-5">
          <div class="border-l-4 border-r-4 p-0.5 rounded dark:border-lime-600 border-2">
            <div @click="toggleAccordion(index)" class="cursor-pointer flex justify-between items-center dark:text-lime-400 text-white p-4 font-bold rounded bg-blue-500 dark:bg-gray-900 dark:border-lime-500">
              <div>
                <span v-if="this.$i18n.locale == 'ru'">{{ vacancy.departmentNameRu }} - {{ vacancy.departmentNameAboutRu }}</span>
                <span v-if="this.$i18n.locale == 'uz'">{{ vacancy.departmentNameUz }} - {{ vacancy.departmentNameAboutUz }}</span>
                <span v-if="this.$i18n.locale == 'en'">{{ vacancy.departmentNameEn }} - {{ vacancy.departmentNameAboutEn }}</span>
              </div>
              <div>
                <svg v-if="isAccordionOpen(index)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"/>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                </svg>
              </div>
            </div>
            <div v-show="isAccordionOpen(index)" class="p-4 rounded-lg bg-gray-100 dark:bg-gray-777 dark:border-lime-500" role="alert">
              <slot>
                <div class="lg:flex justify-between sm:flex-wrap">
                  <div class="flex-initial lg:w-1/2 sm:w-full">
                    <h2 v-if="this.$i18n.locale == 'ru'" class="font-bold text-gray-700 dark:text-white text-xl">{{ vacancy.titleRu }}</h2>
                    <h2 v-if="this.$i18n.locale == 'uz'" class="font-bold text-gray-700 dark:text-white text-xl">{{ vacancy.titleUz }}</h2>
                    <h2 v-if="this.$i18n.locale == 'en'" class="font-bold text-gray-700 dark:text-white text-xl">{{ vacancy.titleEn }}</h2>
                  </div>
                  <div class="flex-nowrap lg:w-1/3 sm:w-full">
                    <h2 class="font-medium text-blue-500 dark:text-white pb-2">{{ $t('vacancy.aplicationDeadline') }}: {{ this.formatedDate(vacancy.expireDate) }} {{ $t('vacancy.ended-text') }}</h2>
                    <h2 class="font-medium text-blue-500 dark:text-white pb-2">{{ $t('vacancy.aplication-testing') }}: {{ this.formatedDate(vacancy.dateOfTesting) }}</h2>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-nowrap">
                    <button v-if="new Date(vacancy.dateOfTesting) >= this.today" class="2xl:text-base xl:text-base lg:text-base md:text-base sm:text-xs pt-10 text-xs leading-relaxed text-blue-500 dark:text-white items-end" @click="goTo(index)">{{ $t('home-page.watch') }}</button>
                    <button v-if="new Date(vacancy.dateOfTesting) < this.today" class="2xl:text-base xl:text-base lg:text-base md:text-base sm:text-xs pt-10 text-xs leading-relaxed text-blue-500 dark:text-white items-end" @click="goToResult(index)">{{ $t('home-page.watch-result') }}</button>
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingState from "@/components/LoadingState";
import axios from 'axios';

export default {
  components: {
    LoadingState,
  },
  data() {
    return {
      vacancies: [],
      loading: true,
      openIndex: null,
      today: new Date(),
    };
  },
  methods: {
    loadTable() {
      axios
        .get(`/api/Website/Vacancy/GetVacancies`)
        .then((response) => {
          this.vacancies = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatedDate(item) {
      return new Date(item).toLocaleDateString('ru-RU');
    },
    goTo(index) {
      sessionStorage.setItem("VACANCY", JSON.stringify(this.vacancies[index]));
      this.$router.push("/instructions");
    },
    goToResult(index) {
      sessionStorage.setItem("VACANCY_ID", JSON.stringify(this.vacancies[index].id));
      this.$router.push("/vacancyResults");
    },
    toggleAccordion(index) {
      this.openIndex = this.openIndex === index ? null : index;
    },
    isAccordionOpen(index) {
      return this.openIndex === index;
    },
  },
  mounted() {
    this.loadTable();
  },
};
</script>
