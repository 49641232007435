import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import i18n from "./i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import VueGtag from "vue-gtag";
import 'aos/dist/aos.css'
import VueSplide from '@splidejs/vue-splide';
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

createApp(App)
    .use(i18n)
    .use(router)
    .use(VueSplide)
    .use(VueAxios, axios)
    .use(
        VueGtag,
        {
            appName: "uzcsd.uz",
            pageTrackerScreenviewEnabled: true,
            config: {id: "G-ETB22PCT49"},
        },
        router
    )
    .use(Vue3Toasity, {autoClose: 3000})
    .mount("#app");
