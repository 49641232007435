<template>
  <div v-if="isLoading">
    <LoadingState></LoadingState>
  </div>
  <div v-else>
  <div class="scroll_container">
    <div class="h-[56px] bg-blue-500 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden"></div>
      <div class="bg-white dark:bg-gray-777 pb-10">
        <section ref="slider" class="w-full">
          <Splide :options="options" class="2xl:block xl:block lg:block md:block sm:block hidden">
            <SplideSlide class="first relative 2xl:h-[500px] xl:h-[400px] lg:h-[400px] md:h-[300px] h-[300px]">
              <div class="container mx-auto flex flex-col justify-center items-center h-full z-[51] space-y-5 pt-10">
                <h2 class="text-white 2xl:text-7xl xl:text-7xl lg:text-4xl text-2xl font-bold text-center tracking-wide">
                  MyCABINET</h2>
                <h3 class="text-white text-center text-xl">{{ $t('home-page.home-page-csd') }}</h3>
                <a href="https://pc.uzcsd.uz" target="_blank" type="button"
                   class="w-64 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg font-medium px-5 py-2.5 text-center mr-2 mb-2 "><span
                    class="animate-pulse">{{ $t("home-page.website") }}</span></a>
              </div>
            </SplideSlide>
            <SplideSlide class="second relative 2xl:h-[500px] xl:h-[400px] lg:h-[400px] md:h-[300px] h-[300px] pt-10">
              <div class="container mx-auto flex flex-col justify-center items-center h-full z-[51] space-y-5">
                <h2 class="text-white 2xl:text-7xl xl:text-7xl lg:text-4xl font-bold text-center tracking-wide">
                  eVOTE</h2>
                <h3 class="text-white text-center text-xl">{{ $t('home-page.home-page-evote') }}</h3>
                <a href="https://evote.uz" target="_blank" type="button"
                   class="w-64 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg font-medium px-5 py-2.5 text-center mr-2 mb-2 "><span
                    class="animate-pulse">{{ $t("home-page.website") }}</span></a>
              </div>
            </SplideSlide>
            <SplideSlide class="third relative 2xl:h-[500px] xl:h-[400px] lg:h-[400px] md:h-[300px] h-[300px] pt-10">
              <div class="container mx-auto flex flex-col justify-center items-center h-full z-[51] space-y-5">
                <h2 class="text-white 2xl:text-7xl xl:text-7xl lg:text-4xl font-bold text-center tracking-wide">
                  DEPO UZCSD</h2>
                <h3 class="text-white text-center text-xl">{{ $t('home-page.home-page-depo') }}</h3>
                <a href="https://depo.uzcsd.uz" target="_blank" type="button"
                   class="w-64 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg font-medium px-5 py-2.5 text-center mr-2 mb-2 "><span
                    class="animate-pulse">{{ $t("home-page.website") }}</span></a>
              </div>
            </SplideSlide>
          </Splide>

          <h2 class="2xl:text-2xl xl:text-2xl lg:text-2xl md:text-xl text-center text-2xl 2xl:py-8 xl:py-8 lg:py-8 py-5 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0 mt-10 dark:text-lime-500">{{ $t('rebrand.our-services') }}</h2>

          <div
              class="container mx-auto grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 grid-cols-1 gap-5 items-center px-4">
            <ServicesCard path="/depoService" :description="$t('rebrand.depo-info')"
                          :header="$t('navbar.depository-services')"
                          :image="require('@/assets/SVG/services-1.svg')"/>
            <ServicesCard path="/itService" :description="$t('rebrand.it-info')"
                          :header="$t('navbar.information-services')"
                          :image="require('@/assets/SVG/services-2.svg')"/>
            <ServicesCard path="/consultService" :description="$t('rebrand.it-info')"
                          :header="$t('navbar.advisory-services')" :image="require('@/assets/SVG/services-3.svg')"/>
            <ServicesCard path="/newServices" :description="$t('rebrand.new-info')"
                          :header="$t('navbar.new-services')" :image="require('@/assets/SVG/services-4.svg')"/>
            <ServicesCard path="/activeTarif" :description="$t('rebrand.tarif-info')" :header="$t('navbar.tarifs')"
                          :image="require('@/assets/SVG/services-5.svg')"/>
            <ServicesCard path="/contacts" :description="$t('rebrand.contact-info')" :header="$t('navbar.contacts')"
                          :image="require('@/assets/SVG/services-6.svg')"/>
          </div>
        </section>
      </div>

      <section ref="services" class="bg-slate-100 py-10 dark:bg-gray-900">
        <div class="pb-5">
          <h2 class="2xl:text-2xl xl:text-2xl lg:text-2xl md:text-xl text-lg text-center mb-10 dark:text-lime-500">{{ $t('rebrand.csd') }}</h2>
          <div
              class="container mx-auto grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-5 items-start justify-start">
            <ProfileCard :image="require('@/assets/SVG/profile-1.svg')" :description="$t('rebrand.profile-one-info')"
                         :header="$t('rebrand.profile-one')"/>
            <ProfileCard :image="require('@/assets/SVG/profile-2.svg')"
                         :description="$t('rebrand.profile-two-info')" :header="$t('rebrand.profile-two')"/>
            <ProfileCard :image="require('@/assets/SVG/profile-3.svg')"
                         :description="$t('rebrand.profile-three-info')" :header="$t('rebrand.profile-three')"/>
            <ProfileCard :image="require('@/assets/SVG/profile-4.svg')" :description="$t('rebrand.profile-four-info')"
                         :header="$t('rebrand.profile-four')"/>
            <ProfileCard :image="require('@/assets/SVG/profile-5.svg')" :description="$t('rebrand.profile-five-info')"
                         :header="$t('rebrand.profile-five')"/>
            <ProfileCard :image="require('@/assets/SVG/profile-6.svg')"
                         :description="$t('rebrand.profile-six-info')" :header="$t('rebrand.profile-six')"/>
          </div>
        </div>

        <div class="mx-2">
          <div
              class="container mx-auto grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-1 items-center shadow-xl">
            <div data-aos="fade-right"
                 class="flex justify-center items-start space-y-4 flex-col p-5 2xl:h-40 xl:h-40 lg:h-40 md:h-40 h-28 cursor-default bg-blue-400 dark:bg-lime-400">
              <h3 class="text-white text-xl font dark:text-gray-900">{{ $t('rebrand.statistics-one') }}</h3>
              <h2 class="text-white 2xl:text-5xl xl:text-5xl lg:text-5xl md:text-3xl text-xl font-bold">
                <vue3-autocounter ref='counter' :startAmount='0' :endAmount='this.statistics.issuers' :duration='3' separator=' '
                                  :autoinit='true'/>
              </h2>
            </div>

            <div data-aos="fade-down"
                 class="flex justify-center items-start space-y-4 flex-col p-5 2xl:h-40 xl:h-40 lg:h-40 md:h-40 h-28 cursor-default bg-blue-500 dark:bg-lime-500">
              <h3 class="text-white text-xl font dark:text-gray-900">{{ $t('rebrand.statistics-two') }}</h3>
              <h2 class="text-white 2xl:text-5xl xl:text-5xl lg:text-5xl md:text-3xl text-xl font-bold">
                <vue3-autocounter ref='counter' :startAmount='0' :endAmount='this.statistics.depositors' :duration='3' separator=' '
                                  :autoinit='true'/>
              </h2>
            </div>

            <div data-aos="fade-left"
                 class="flex justify-center items-start space-y-4 flex-col p-5 2xl:h-40 xl:h-40 lg:h-40 md:h-40 h-28 cursor-default bg-blue-600 dark:bg-lime-600">
              <h3 class="text-white text-xl font dark:text-gray-900">{{ $t('rebrand.statistics-three') }}</h3>
              <h2 class="text-white 2xl:text-5xl xl:text-5xl lg:text-5xl md:text-3xl text-xl font-bold">
                <vue3-autocounter ref='counter' :startAmount='0' :endAmount='this.statistics.brokers' :duration='3' separator=' '
                                  :autoinit='true'/>
              </h2>
            </div>

            <div data-aos="fade-right"
                 class="flex justify-center items-start space-y-4 flex-col p-5 2xl:h-40 xl:h-40 lg:h-40 md:h-40 h-28 cursor-default bg-blue-500 dark:bg-lime-500">
              <h3 class="text-white text-xl font dark:text-gray-900">{{ $t('rebrand.statistics-four') }}</h3>
              <h2 class="text-white 2xl:text-5xl xl:text-5xl lg:text-5xl md:text-3xl text-xl font-bold">
                <vue3-autocounter ref='counter' :startAmount='0' :endAmount='this.statistics.investmentFunds' :duration='3' separator=' '
                                  :autoinit='true'/>
              </h2>
            </div>

            <div data-aos="fade-up"
                 class="flex justify-center items-start space-y-4 flex-col p-5 2xl:h-40 xl:h-40 lg:h-40 md:h-40 h-28 cursor-default bg-blue-600 dark:bg-lime-600">
              <h3 class="text-white text-xl font dark:text-gray-900">{{ $t('rebrand.statistics-fifth') }}</h3>
              <h2 class="text-white 2xl:text-5xl xl:text-5xl lg:text-5xl md:text-3xl text-xl font-bold">
                <vue3-autocounter ref='counter' :startAmount='0' :endAmount='this.statistics.shareholdersOfInvestmentFunds' :duration='3' separator=' '
                                  :autoinit='true'/>
              </h2>
            </div>

            <div data-aos="fade-left"
                 class="flex justify-center items-start space-y-4 flex-col p-5 2xl:h-40 xl:h-40 lg:h-40 md:h-40 h-28 cursor-default bg-blue-700 dark:bg-lime-700">
              <h3 class="text-white text-xl font dark:text-gray-900">{{ $t('rebrand.statistics-six') }}</h3>
              <h2 class="text-white 2xl:text-5xl xl:text-5xl lg:text-5xl md:text-3xl text-xl font-bold">
                <vue3-autocounter ref='counter' :startAmount='0' :endAmount='this.statistics.trustManagers' :duration='3' separator=' '
                                  :autoinit='true'/>
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section ref="info">
        <h2 class="2xl:text-2xl xl:text-2xl lg:text-2xl md:text-xl text-lg text-center py-10">{{ $t('rebrand.open-info') }}</h2>
        <div
            class="container mx-auto grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 grid-cols-1 gap-5 items-center px-4">
          <ServicesCard
              path="/isinAndcfi"
              :header="$t('rebrand.list-of-isin')"
              :image="require('@/assets/SVG/services-7.svg')"/>
          <ServicesCard
              path="/issuersList"
              :header="$t('rebrand.list-of-emitents')"
              :image="require('@/assets/SVG/services-8.svg')"/>
          <ServicesCard
              path="/developing"
              :header="$t('rebrand.search-securities')" :image="require('@/assets/SVG/services-9.svg')"/>
          <ServicesCard
              path="/developing"
              :header="$t('rebrand.search-emitents')" :image="require('@/assets/SVG/services-10.svg')"/>
          <a href="https://depo.uzcsd.uz/compasses" target="_blank">
            <ServicesCard
                :header="$t('rebrand.compass')"
                :image="require('@/assets/SVG/services-11.svg')"/>
          </a>
          <a href="https://depo.uzcsd.uz/messages" target="_blank">
            <ServicesCard
                :header="$t('rebrand.vestnik')"
                :image="require('@/assets/SVG/services-12.svg')"/>
          </a>
        </div>

        <div class="bg-slate-100 py-5 mt-10 dark:bg-gray-900">
          <h2 class="2xl:text-2xl xl:text-2xl lg:text-2xl md:text-xl text-lg text-center mb-5 dark:text-lime-500">{{ $t('rebrand.news') }}</h2>

          <div
              class="container mx-auto grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-5 pb-5">
            <NewsCard class="transition-all ease-linear" data-aos="fade-down" :id="item.id" v-for="item in this.newsData" :key="item.id"
                      :imageSrc="item.news.filter((e) => e.language === this.$i18n.locale)[0]
                  .coverBase64"
                      :title="item.news.filter((e) => e.language === this.$i18n.locale)[0]
                  .title"
                      :date="this.formatedDate(item.posted)"
            />
          </div>
        </div>

      </section>

      <section id="partners" class="2xl:block xl:block lg:block md:block hidden bg-slate-100 dark:bg-gray-777">
        <h2 class="2xl:text-2xl xl:text-2xl lg:text-2xl md:text-xl text-lg text-center pb-10">{{ $t('footer.useful-links') }}</h2>
        <div class="container mx-auto pb-10">
          <Splide :options="second">
            <SplideSlide>
              <PartnersCard :title="$t('useful.first')" :image="require('@/assets/img/links/imv.webp')"
                            :link='"https://www.imv.uz/"'/>
            </SplideSlide>
            <SplideSlide>
              <PartnersCard :title="$t('useful.second')" :image="require('@/assets/img/links/2.webp')"
                            :link='"https://cbu.uz/"'/>
            </SplideSlide>
            <SplideSlide>
              <PartnersCard :title="$t('useful.third')" :image="require('@/assets/img/links/depoImg.webp')"
                            :link='"https://davaktiv.uz"'/>
            </SplideSlide>
            <SplideSlide>
              <PartnersCard :title="$t('useful.fourth')" :image="require('@/assets/img/links/5.webp')"
                            :link='"https://uzse.uz/"'/>
            </SplideSlide>
            <SplideSlide>
              <PartnersCard :title="$t('useful.fifth')" :image="require('@/assets/img/links/6.svg')"
                            :link='"https://lex.uz/uz/"'/>
            </SplideSlide>
            <SplideSlide>
              <PartnersCard :title="$t('useful.sixth')" :image="require('@/assets/img/links/7.webp')"
                            :link='"https://aecsd.org/"'/>
            </SplideSlide>
            <SplideSlide>
              <PartnersCard :title="$t('useful.seventh')" :image="require('@/assets/img/links/8.jpg')"
                            :link='"https://www.acgcsd.org/"'/>
            </SplideSlide>
            <SplideSlide>
              <PartnersCard :title="$t('useful.eighth')" :image="require('@/assets/img/links/depoImg.webp')"
                            :link='"https://my.gov.uz/ru"'/>
            </SplideSlide>
            <SplideSlide>
              <PartnersCard :title="$t('useful.ninth')" :image="require('@/assets/img/links/9.png')"
                            :link='"https://napp.uz/"'/>
            </SplideSlide>
          </Splide>
        </div>
      </section>

    </div>
  </div>
</template>

<script>
import AOS from 'aos';
import '@splidejs/vue-splide/css';
import Vue3Autocounter from "vue3-autocounter";
import ServicesCard from "@/components/ServicesCard.vue";
import ProfileCard from "@/components/ProfileCard.vue";
import NewsCard from "@/components/NewsCard.vue";
import PartnersCard from "@/components/PartnersCard.vue";
import LoadingState from "@/components/LoadingState.vue";

export default {
  components: {
    LoadingState,
    ServicesCard,
    ProfileCard,
    NewsCard,
    Vue3Autocounter,
    PartnersCard
  },
  data() {
    return {
      newsData: [],
      isLoading: true,
      options: {
        rewind: true,
        autoplay: true,
        type: 'loop',
        interval: 10000,
        pagination: false,
        easing: 'linear',
      },
      second: {
        rewind: true,
        type: 'loop',
        perPage: 4,
        perMove: 1,
        autoplay: true,
        pagination: false,
        arrows: true,
        gap: 15,
      },
      statistics: {
        issuers: 0,
        depositors: 0,
        brokers: 0,
        investmentFunds: 0,
        shareholdersOfInvestmentFunds: 0,
        trustManagers: 0
      }
    };
  },
  methods: {
    formatedDate(item) {
      const date = new Date(item).toLocaleDateString("ru-RU");
      return date;
    },
    getStatistics(){
      this.axios
          .get("/api/Website/Statistic/GetStatistic")
          .then((response) => {
            this.statistics = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
    },
    loadData() {
      this.axios
          .get("/api/Website/GetLastFourNews")
          .then((response) => {
            this.newsData = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    }
    
  },
  mounted() {
    this.getStatistics();
    this.loadData();
    AOS.init();
  }
}
</script>

<style scoped>
.scroll_container {
  overflow-y: hidden;
  overflow-x: hidden;
}

.first {
  background-image: url("@/assets/pc.webp");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.second {
  background-image: url("@/assets/evote.webp");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.third {
  background-image: url("@/assets/depo.webp");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>